import './Loading.css'
import { VscLoading } from "react-icons/vsc";

function Loading() {
  return (
    <div className="loading-main-container">
        <h1 className="texto-carregando">Carregando...</h1>
        <VscLoading className="icone-loading" />
    </div>
  )
}

export default Loading