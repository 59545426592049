import axios from "axios";
import API_BASE_URL from "../config/apiConfig";
import { limparTodosCaches, recuperarCacheSemDescriptografia } from "./cacheHandler";
import { isTokenExpired } from "./jwtTokenService";

const api = axios.create({
    baseURL: API_BASE_URL
});

api.interceptors.request.use(
    async (config) => {
        const token = await recuperarCacheSemDescriptografia('access_token');
        if (token) {
            let tokenExpirado = isTokenExpired(token);
            
            if(tokenExpirado) {
                limparTodosCaches();
                window.location.href = "/login";
                return;
            } else {
                config.headers.Authorization = `Bearer ${token}`;
            }
        }
        return config;
    },
    (error) => Promise.reject(error)
);

export default api;