import MenuLateral from "../components/MenuLateral/MenuLateral";
import Header from "../components/Header/Header";
import EmpresasContainer from "../components/EmpresasContainer/EmpresasContainer";
import { EmpresaFilterProvider } from "../context/EmpresaFilterContext";

export default function Empresas() {
  return (
    <div className="main-container">
      <EmpresaFilterProvider>
        <Header />
        <MenuLateral telaAtual={"empresas"} />
        <EmpresasContainer />
      </EmpresaFilterProvider>
      </div>
  )
}
