import { IoMdEye, IoMdEyeOff, IoMdLock } from 'react-icons/io';
import './InputSenha.css';
import { useState } from 'react';

function InputSenha({
    placeholder,
    onChange,
    tipoInput,
    comErro
}) {

    const [showPassword, setShowPassword] = useState(false);
  
    const toggleShowPassword = () => {
      setShowPassword(!showPassword);
    };

    return (
        <div className={`password-input-container  ${comErro ? "erro-invalido" : ""}`}>
            <IoMdLock className="icon-lock" />
            <input
                type={showPassword ? 'text' : 'password'}
                onChange={(e) => onChange(tipoInput, e.target.value)}
                placeholder={placeholder}
                className={`password-input-field`}
            />
            <div className="icon-eye-container" onClick={toggleShowPassword}>
                {showPassword ? <IoMdEye className="icon-eye mostra-senha" /> : <IoMdEyeOff className="icon-eye" />}
            </div>
      </div>
    )
}

export default InputSenha;