import ListaChamados from '../ListaChamados/ListaChamados'
import TopContainer from '../TopContainer/TopContainer'
import './Container.css'
import { TicketFilterProvider } from '../../context/TicketFilterContext'

function Container() {
  return (
    <div className="container">
        <TicketFilterProvider>
          <TopContainer />
          <ListaChamados />
        </TicketFilterProvider>
    </div>
  )
}

export default Container