import './MenuLateral.css'
import React, { useState } from 'react'
import Logo from '../../img/dixi_ponto_banner.png'
import { FaTicketAlt, FaSignOutAlt } from "react-icons/fa";
import { LuMailPlus } from "react-icons/lu";
import { IoIosCreate, IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { useAuth } from '../../services/AuthContext';
import { validaPermissao } from '../../utils/PermissionsUtils';
import { Link } from 'react-router-dom';

function MenuLateral({ telaAtual }) {
    const { user, logout } = useAuth();

    const [menuAberto, setMenuAberto] = useState(false);

    const permCadastros = validaPermissao('CADASTROS', user.perfilUsuario.idPerfilUsuario);
    const permEmpresas = validaPermissao('EMPRESAS', user.perfilUsuario.idPerfilUsuario);
    const permDepartamentos = validaPermissao('DEPARTAMENTOS', user.perfilUsuario.idPerfilUsuario);
    const permUsuarios = validaPermissao('USUARIOS', user.perfilUsuario.idPerfilUsuario);

    let ticketPage = false;
    let openTicketPage = false;
    let configPage = false;
    let empresas = false;
    let usuarios = false;
    let departamentos = false;

    switch (telaAtual) {
        case "painel-chamados":
            ticketPage = true;
            break;
        case "abrir-chamado":
            openTicketPage = true;
            break;
        case "config-sistema":
            configPage = true;
            break;
        case "empresas":
            empresas = true;
            break;
        case "usuarios":
            usuarios = true;
            break;
        case "departamentos":
            departamentos = true;
            break;
        default:
            ticketPage = true;
            break;
    }

    const telaSubMenu = empresas || usuarios || departamentos;

    const handleLogout = () => {
        logout();
    }

    const handleOpenMenu = (e) => {
        e.preventDefault();
        setMenuAberto(!menuAberto);
    }

    return (
        <menu>
            <img className="logo-banner-dixi" src={Logo} alt="Logo da Dixi"/>

            <ul id="main-menu">
                <Icon destinoConvert={"TELA INICIAL"} classeComplementar={"tela-inicial"} classe={ticketPage ? 'tela-atual' : ''} icon={<FaTicketAlt />} destino={ticketPage ? '#' : '/'}/>

                {permCadastros 
                    ? (
                        <div 
                            className="container-dropdown-cadastros"
                            onClick={(e) => {handleOpenMenu(e)}}>
                                <Icon 
                                    destinoConvert={"CADASTROS"} 
                                    classeComplementar={"menu-cadastros"}
                                    classe={telaSubMenu ? 'tela-atual' : ''}
                                    icon={<IoIosCreate />}
                                    destino={'#'}
                                    dropMenuAtivo={true}
                                    dropMenu={!menuAberto ? <IoMdArrowDropdown className={`seta-dropdown-cadastros ${telaSubMenu ? 'tela-atual' : ''}`} /> : <IoMdArrowDropup className={`seta-dropdown-cadastros ${telaSubMenu ? 'tela-atual' : ''}`} />}
                                />

                            <ul className={`dropdown-cadastros ${menuAberto ? 'dropdown-aberto' : ''}`}>
                                {permEmpresas 
                                    ? <SubMenu
                                        nessaTela={empresas}
                                        classe={`sub-meun-dropdown`}
                                        destino={'/empresas'}
                                        destinoConvert={"EMPRESA"}
                                        /> : ''}

                                {permDepartamentos 
                                    ? <SubMenu
                                        nessaTela={departamentos}
                                        classe={`sub-meun-dropdown`}
                                        destino={'/departamentos'}
                                        destinoConvert={"DEPARTAMENTO"}
                                        /> : ''}
                                
                                {permUsuarios 
                                    ? <SubMenu
                                        nessaTela={usuarios}
                                        classe={`sub-meun-dropdown`}
                                        destino={'/usuarios'}
                                        destinoConvert={"USUÁRIO"}
                                        /> : ''}
                            </ul>
                        </div>
                    ) 
                    : ''}
                

                <Icon destinoConvert={"ABRIR CHAMADO"} classeComplementar={"abrir-chamado"} classe={openTicketPage ? 'tela-atual' : ''} icon={<LuMailPlus />} destino={openTicketPage ? '#' : '/abrir-chamado'}/>
            </ul>

            <ul id="menu-inferior">
                <Icon onClick={handleLogout} destinoConvert={"SAIR"} classeComplementar={"sair"} classe={"botao-sair"} icon={<FaSignOutAlt />}/>
            </ul>
        </menu>
    )
}

const Icon = ({ icon, classe, destino, destinoConvert, classeComplementar, dropMenu, dropMenuAtivo, onClick }) => {
    return (
        <li onClick={onClick} className={classe}>
            <Link to={destino}>
                {icon}
                <span className={`tooltip-navbar-lateral ${classeComplementar}`} >{destinoConvert} {dropMenuAtivo ? dropMenu : ''}</span>
            </Link>
        </li>
    )
}

const SubMenu = ({ classe, destino, destinoConvert, nessaTela, onClick }) => {
    return (
        <li
            className={classe}
            onClick={onClick}
        >
            <Link 
                id={nessaTela ? 'tela-atual-sub-menu' : 'nao-e-tela-atual-sub-menu'}
                to={destino}>
                {destinoConvert}
            </Link>
        </li>
    )
}

export default MenuLateral