import { useContext, useEffect, useState } from 'react';
import { useAuth } from '../../services/AuthContext';
import FiltroDepartamentoContainer from '../FiltroDepartamentoContainer/FiltroDepartamentoContainer';
import ListViewDepartamento from '../ListViewDepartamento/ListViewDepartamento';
import './DepartamentoContainer.css';
import { DepartamentoFilterContext } from '../../context/DepartamentoFilterContext';
import Loading from '../Loading/Loading';
import { getDepartamentos, getDepartamentosFiltrados } from '../../services/departamentoService';

function DepartamentoContainer() {

  const { user } = useAuth();
  const [ loading, setLoading ] = useState(true);

  const { departamentoFilter, filtrar, temFiltro } = useContext(DepartamentoFilterContext);

  const [ page, setPage ] = useState(0);
  const [ infoPagina, setInfoPagina ] = useState({});

  const [ departamentos, setDepartamentos ] = useState([]);

  useEffect(() => {
    async function fetchDepartamentos() {
      let response = null;
      setLoading(true);

      if(temFiltro()) {
        response = await getDepartamentosFiltrados(departamentoFilter, page);
      } else {
        response = await getDepartamentos(page);
      }

      setDepartamentos(response.content);
      setInfoPagina(response);
      setLoading(false);
    }

    fetchDepartamentos();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filtrar]);

  const podeCadastrar = user.podeCadastrarDepartamento;

  return (
    <div className="container">
        {loading && <Loading />}

        <FiltroDepartamentoContainer
          podeCadastrar={podeCadastrar}
        />

        <ListViewDepartamento
          departamentos={departamentos}
          page={page}
          infoPagina={infoPagina}
          setPage={setPage}
        />
    </div>
  )
}

export default DepartamentoContainer;