import { useState } from 'react';
import './LoginForm.css';
import LogoBanner from '../LogoBanner/LogoBanner';
import { validaEmail, validaSenha } from '../../validations/basicAuthValidations';
import NotifyUtils from '../../utils/NotifyUtils';

const LoginForm = ({ onLogin: handleLogin }) => {

  const [ loading, setLoading ] = useState(false);

  const [ contadorLogin, setContadorLogin ] = useState(0);

  const [ lastTryTime, setLastTryTime ] = useState(new Date().getTime());

  const timeOutTime = 30000;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [emailInvalido, setEmailInvalido] = useState(false);
  const [senhaInvalida, setSenhaInvalida] = useState(false);

  const handleTogglePassword = () => {
    // Inverte o estado para mostrar ou ocultar a senha
    setShowPassword(!showPassword);
  };

  const handleLoginClick = async () => {
    if(loading) {
      NotifyUtils.aviso("Aguarde o processamento do login...");
      return;
    };

    setLoading(true);

    const emailValido = validaEmail(email);
    const senhaValida = validaSenha(password);

    setEmailInvalido(!emailValido);
    setSenhaInvalida(!senhaValida);

    if (emailValido && senhaValida) {
      const currentTime = new Date().getTime();
      if(contadorLogin > 5) {
        const timeBetweenLogins = currentTime - lastTryTime;
        const tempoRestante = Math.floor((timeBetweenLogins - timeOutTime) / 1000);
  
        if(timeBetweenLogins < timeOutTime) {
          NotifyUtils.erro(`Você atingiu o limite de tentativas. Aguarde ${tempoRestante * -1} segundos e tente novamente.`);
          setLoading(false);
          return;
        }
      }
      
      setLastTryTime(currentTime);
      await handleLogin(email, password);
      setLoading(false);
    } else {
      NotifyUtils.erro(`${retornaStringInvalido(emailValido, senhaValida)}`);
      setLoading(false);
    }

    setContadorLogin(contadorLogin + 1);
  };

  const retornaStringInvalido = (emailValido, senhaValida) => {
    let retorno = "";

    if(!emailValido && senhaValida) retorno += "O campo deve ser um e-mail!";
    if(emailValido && !senhaValida) retorno += "A senha deve conter 3 ou mais caracteres!";

    if(!emailValido && !senhaValida) {
      retorno = "E-mail e senha inválidos!";
    }

    return retorno;
  }

  const handleKeyPress = async (e) => {
    if(e.key === "Enter") {
      await handleLoginClick();
    }
  };

  const naoDisponivel = (event) => {
    event.preventDefault();
    NotifyUtils.custom("Função em desenvolvimento...");
  }

  return (
    <div className="login-form" onKeyDown={handleKeyPress}>
      <LogoBanner  />

      <div className="input-container">
        <i className="login-user-icon material-icons-outlined">person</i>
        <input
          className={emailInvalido ? "invalido" : null}
          type="email"
          placeholder="E-MAIL"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>

      <div className="input-container">
        <i className="login-lock-icon material-icons-outlined">lock</i>
        <input
          className={senhaInvalida ? "invalido" : null}
          type={showPassword ? "text" : "password"}
          placeholder="SENHA"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <i className={`login-eye-icon ${showPassword ? 'material-icons' : 'material-icons-outlined'} ${showPassword ? 'olho-aberto' : null}`} onClick={handleTogglePassword}>{showPassword ? "visibility" : "visibility_off"}</i>
      </div>

      <a onClick={(e) => {naoDisponivel(e)}} href="/recuperar-senha">Esqueceu a senha?</a>
      <button onClick={handleLoginClick}>ENTRAR</button>
    </div>
  );
};

export default LoginForm;
