// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.botao-padrao {
    color: var(--branco-padrao);
    background: var(--azul-dixi);
    font-weight: 500;
    font-size: 1rem;
    padding: .6rem 1.5rem;
    width: max-content;
    min-width: 12rem;
    border-radius: 8px;
    border: none;
    transition: all .3s;
    cursor: pointer;
}

.botao-padrao:hover {
    background: var(--botao-hover);
}

.botao-padrao-cancelar {
    background-color: var(--branco-padrao);
    outline: 2px solid var(--azul-dixi);
    color: var(--azul-dixi);
    padding: .50rem 0;
}

.botao-padrao-cancelar:hover {
    background-color: var(--azul-dixi);
    color: var(--branco-padrao);
}

.botao-padrao-disabled {
    background: var(--cinza-padrao2);
    cursor: not-allowed;
}

.botao-padrao-disabled:hover {
    background: var(--cinza-padrao2);
}`, "",{"version":3,"sources":["webpack://./src/components/BotaoPadrao/BotaoPadrao.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,4BAA4B;IAC5B,gBAAgB;IAChB,eAAe;IACf,qBAAqB;IACrB,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,YAAY;IACZ,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,sCAAsC;IACtC,mCAAmC;IACnC,uBAAuB;IACvB,iBAAiB;AACrB;;AAEA;IACI,kCAAkC;IAClC,2BAA2B;AAC/B;;AAEA;IACI,gCAAgC;IAChC,mBAAmB;AACvB;;AAEA;IACI,gCAAgC;AACpC","sourcesContent":[".botao-padrao {\n    color: var(--branco-padrao);\n    background: var(--azul-dixi);\n    font-weight: 500;\n    font-size: 1rem;\n    padding: .6rem 1.5rem;\n    width: max-content;\n    min-width: 12rem;\n    border-radius: 8px;\n    border: none;\n    transition: all .3s;\n    cursor: pointer;\n}\n\n.botao-padrao:hover {\n    background: var(--botao-hover);\n}\n\n.botao-padrao-cancelar {\n    background-color: var(--branco-padrao);\n    outline: 2px solid var(--azul-dixi);\n    color: var(--azul-dixi);\n    padding: .50rem 0;\n}\n\n.botao-padrao-cancelar:hover {\n    background-color: var(--azul-dixi);\n    color: var(--branco-padrao);\n}\n\n.botao-padrao-disabled {\n    background: var(--cinza-padrao2);\n    cursor: not-allowed;\n}\n\n.botao-padrao-disabled:hover {\n    background: var(--cinza-padrao2);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
