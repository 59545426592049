import { montaUrlFiltroPaginacaoDepartamento } from "../utils/DataUtils";
import api from "./api";

const endpoint = "/departamento_";
const PAGE_SIZE = 5;

export const createDepartamento = async (departamento) => {
    try {
        const response = await api.post(endpoint, departamento, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export const getDepartamentos = async (page) => {
    try {
        const response = await api.get(endpoint + "?page=" + page + "&size=" + PAGE_SIZE);
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
}

export const getDepartamentosFiltrados = async (departamentoFilter, page) => {
    try {
        const response = await api.get(montaUrlFiltroPaginacaoDepartamento(endpoint, departamentoFilter, page, PAGE_SIZE));
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
}

export const getDepartamentoById = async (idDepartamento) => {
    try {
        const response = await api.get(endpoint + `/${idDepartamento}`);
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
}

export const updateDepartamento = async (departamento) => {
    try {
        const response = await api.put(endpoint + `/${departamento.idDepartamento}`, departamento, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
}