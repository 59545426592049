import { createContext, useState } from 'react';
import { createDepartamento } from '../services/departamentoService';
import { useNavigate } from 'react-router-dom';
import { validaCampoVazio, validaSigla } from '../validations/basicsValidations';
import { montaResposta } from '../utils/ResponsesUtils';

export const DepartamentoContext = createContext();

export const DepartamentoProvider = ({ children }) => {
    const navigate = useNavigate();

    const [departamentoData, setDepartamentoData] = useState({
        nomeDepartamento: '',
        sigla: '',
        descricao: '',
        situacaoCadastro: '',
    });

    // Função para atualizar o estado do departamento que está sendo cadastrada (um por vez)
    const handleChange = (field, value) => {
        setDepartamentoData(estadoAnterior => ({
            ...estadoAnterior,
            [field]: value,
        }));
    };

    const handleSubmit = async () => {
        // Faz todas as validações para verificar se os campos estão corretamente preenchidos
        const nomeValido = validaCampoVazio(departamentoData.nomeDepartamento);
        const situacaoValida = validaCampoVazio(departamentoData.situacaoCadastro);
        const siglaValida = validaSigla(departamentoData.sigla);

        if(nomeValido
            && situacaoValida 
            && siglaValida) {

            try {
                departamentoData.sigla = departamentoData.sigla.toUpperCase();
            } catch (error) {
                console.error(error);
                return null;
            }

            return await createDepartamento(departamentoData);
        } else {
            // Retorna todos os campos que estão inválidos
            return montaResposta(
                undefined, 
                'Preencha corretamente os campos obrigatórios.',
                null,
                { nomeValido, situacaoValida, siglaValida });
        }
    };

    const handleCancelling = () => {
        handleClear();
        irParaListViewDepartamento();
    };

    const handleClear = () => {
        setDepartamentoData(() => ({
            nomeDepartamento: '',
            sigla: '',
            descricao: '',
            situacaoCadastro: '',
        }));
    };

    const irParaListViewDepartamento = () => {
        navigate('/departamentos');
    };

    return (
        <DepartamentoContext.Provider 
            value={{ departamentoData, handleChange, handleSubmit, handleCancelling }}>
            {children}
        </DepartamentoContext.Provider>
    )
}