import { IoIosWarning } from 'react-icons/io'
import './PaginaNaoEncontrada.css'

function PaginaNaoEncontrada() {
  return (
    <div className="container-nenhuma-pagina-encontrada">
      <Nada />
    </div>
  )
}

const Nada = () => {
  return (
    <div className="nenhum-encontrada-container">
        <IoIosWarning 
            className="icone-pagina-encontrada"
        />
        <h2 className="h2-nenhum-resultado-encontrado">OOPS! ESSA PÁGINA NÃO EXISTE!</h2>
        <h2 className="h3-nenhum-resultado-encontrado">404</h2>
        <p className="referencia-para-tela-inicial">Voltar para a <a href="/">tela inicial</a>.</p>
    </div>
  )
}

export default PaginaNaoEncontrada