import { createContext, useState } from "react";

export const TicketFilterContext = createContext();

export const TicketFilterProvider = ({ children }) => {
    const [ticketFilter, setTicketFilter] = useState('');
    const [tipoFiltro, setTipoFiltro] = useState('');
    const [filtrar, setFiltrar] = useState(false);

    const handleChange = (value) => {
        setTicketFilter(value);
    };

    const temFiltro = () => {
        return ticketFilter !== '';
    };

    const handleSearch = () => {
        setFiltrar(!filtrar);
    };

    return (
        <TicketFilterContext.Provider value={{ 
            ticketFilter, 
            setTicketFilter,
            handleChange, 
            filtrar, 
            setFiltrar, 
            temFiltro,
            tipoFiltro,
            handleSearch,
            setTipoFiltro
        }}>
            {children}
        </TicketFilterContext.Provider>
    );
};
