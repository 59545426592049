import LoginForm from '../components/LoginForm/LoginForm';
import { useAuth } from '../services/AuthContext';

const LoginPage = () => {

  const { login, user } = useAuth();

  if(user) {
    window.location.href = '/';
  }
  
  const handleLogin = async (email, password) => {
    // Lógica de autenticação do login
    const logado = await login(email, password);

    if(logado) {
      // Redireciona para a página inicial
      window.location.href = '/';
    }
  };

  return (
    <div className="login-page">
      <LoginForm onLogin={handleLogin} />
    </div>
  );
};

export default LoginPage;
