import './Checkbox.css'

function Checkbox({ className, idChamado }) {
  return (
    <div className={`checkbox-wrapper-45 ${className}`}>
    <input id={`checkbox-chamado-${idChamado}`} type="checkbox"/>
    <label className="cbx" htmlFor={`checkbox-chamado-${idChamado}`}>
        <div className="flip">
        <div className="front"></div>
        <div className="back">
            <svg width="16" height="14" viewBox="0 0 16 14">
            <path d="M2 8.5L6 12.5L14 1.5"></path>
            </svg>
        </div>
        </div>
    </label>
    </div>
  )
}

export default Checkbox