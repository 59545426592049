import React from 'react'
import Header from '../components/Header/Header'
import MenuLateral from '../components/MenuLateral/MenuLateral'
import { EditarDepartamentoProvider } from '../context/EditarDepartamentoContext'
import { useParams } from 'react-router-dom'
import FormEditarDepartamento from '../components/FormEditarDepartamento/FormEditarDepartamento'

function EditarDepartamento() {

  const { idDepartamento } = useParams();

  return (
    <EditarDepartamentoProvider>
      <div className="main-container">
        <Header />
        <MenuLateral telaAtual={"departamentos"} />
        <FormEditarDepartamento idDepartamento={idDepartamento} />
      </div>
    </EditarDepartamentoProvider>
  )
}

export default EditarDepartamento;