import { useAuth } from '../../services/AuthContext';
import Notificacao from '../Notificacao/Notificacao';
import './Header.css'

function Header() {

  const { user } = useAuth();

  return (
    <div className="header">
        <div className="container-lateral-header">
          <Notificacao />

          <div className="usuario-logado-container">
            <div className="info-usuario-logado">
              <h3>{user.nome}</h3>
              <p>{user.perfilUsuario.descricao}</p>
            </div>

            {/* <div className="img-usuario-logado">
              <img src={logoImage} alt="Logo do usuário logado" />
            </div> */}
          </div>
        </div>
    </div>
  )
}

export default Header