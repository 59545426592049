// Constantes para idPerfilUsuario - 1 admin - 2 gestor - 3 usuario
const PermissionsUtils = {
    admin: 1,
    gestor: 2,
    usuario: 3
};

export const validaPermissao = (tipoPermissao, idPerfilUsuario) => {
    switch (tipoPermissao) {
        case 'CADASTROS':
            return idPerfilUsuario === PermissionsUtils.admin || idPerfilUsuario === PermissionsUtils.gestor;
        case 'USUARIOS':
            return idPerfilUsuario === PermissionsUtils.admin || idPerfilUsuario === PermissionsUtils.gestor;
        case 'EMPRESAS':
            return idPerfilUsuario === PermissionsUtils.admin;
        case 'DEPARTAMENTOS':
            return idPerfilUsuario === PermissionsUtils.admin;
        default:
            return false;
    }
};

export const validaUsarioAdministrador = (usuario) => {
    const isAdmin = usuario.perfilUsuario.idPerfilUsuario === PermissionsUtils.admin;
    return isAdmin;
}

export default PermissionsUtils;