import React from 'react'
import Header from '../components/Header/Header'
import MenuLateral from '../components/MenuLateral/MenuLateral'
import FormCadastroUsuario from '../components/FormCadastroUsuario/FormCadastroUsuario'
import { UsuarioProvider } from '../context/UsuarioContext'

function CadastroUsuario() {
  return (
    <UsuarioProvider>
      <div className="main-container">
        <Header />
        <MenuLateral telaAtual={"usuarios"} />
        <FormCadastroUsuario />
      </div>
    </UsuarioProvider>
  )
}

export default CadastroUsuario