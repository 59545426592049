import Container from "../components/Container/Container";
import MenuLateral from "../components/MenuLateral/MenuLateral";
import Header from "../components/Header/Header";

export default function Home() {
  return (
    <div className="main-container">
      <Header />
      <MenuLateral telaAtual={"painel-chamados"} />
      <Container />
    </div>
  )
}
