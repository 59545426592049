import { Link } from 'react-router-dom';
import './BotaoCustom.css';

function BotaoCustom({
    acaoDeClicar,
    texto,
    confirmar,
    cancelar,
    icone,
    disabled,
    redirecionar
}) {

    const executaAcao = () => {
        if(redirecionar !== undefined) {
            window.location.href = redirecionar;
            return;
        }
        acaoDeClicar();
    };

    return (
        <button 
            disabled={disabled}
            className={
                `${confirmar ? "botao-padrao-custom-confirmar" : ''}
                ${cancelar ? "botao-padrao-custom-cancelar" : ''}
                ${disabled ? "botao-padrao-custom-disabled" : ''}
                botao-padrao-custom `
            }
            onClick={executaAcao}
            >
            {disabled ? 'NÃO DISPONÍVEL' : texto.toUpperCase()}
            {icone && icone}
        </button>
    )
}

export default BotaoCustom;