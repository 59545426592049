import api from "./api";

const endpoint = '/comentario_';

export const createComentario = async (comentario, idUsuario) => {
    try {
        const endpointCompleto = endpoint + "/" + idUsuario;
        const response = await api.post(endpointCompleto, comentario);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export const getComentariosByChamado = async (chamado) => {
    try {
        const response = await api.post(endpoint + `/byChamado`, chamado);
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }

}