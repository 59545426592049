// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* Estilos do "Configurações" */
.configuracoes-chamado-container {
    background-color: var(--branco-padrao);
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    height: max-content;
    min-width: max-content;
}

.container-selects-configuracao {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 90%;
    padding: .6rem 0;
}

/* Estilos data */
.datas-container {
    display: flex;
    flex-direction: column;
    width: 90%;
    gap: .2rem;
    padding: .6rem 0;
    color: var(--azul-dixi);
}

.divisor-datas {
    border: 1px solid var(--azul-dixi);
    margin-bottom: .5rem;
}

.container-junto-data-e-hora {
    display: flex;
    gap: .5rem;
}

.prazo-final-texto {
    display: flex;
    align-items: center;
    font-size: .8rem;
    gap: .2rem;
    color: var(--text-color2);
    font-weight: 600;
}

.prazo-final-texto svg {
    font-size: 1.1rem;
}

/* Configurações botões de canclear e criar */
.botoes-config-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1rem 1.5rem;
    gap: .8rem;
}`, "",{"version":3,"sources":["webpack://./src/components/ConfigAbrirChamado/ConfigAbrirChamado.css"],"names":[],"mappings":";AACA,+BAA+B;AAC/B;IACI,sCAAsC;IACtC,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,wCAAwC;IACxC,kBAAkB;IAClB,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,UAAU;IACV,gBAAgB;AACpB;;AAEA,iBAAiB;AACjB;IACI,aAAa;IACb,sBAAsB;IACtB,UAAU;IACV,UAAU;IACV,gBAAgB;IAChB,uBAAuB;AAC3B;;AAEA;IACI,kCAAkC;IAClC,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,UAAU;AACd;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,UAAU;IACV,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;AACrB;;AAEA,6CAA6C;AAC7C;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,WAAW;IACX,oBAAoB;IACpB,UAAU;AACd","sourcesContent":["\n/* Estilos do \"Configurações\" */\n.configuracoes-chamado-container {\n    background-color: var(--branco-padrao);\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    box-shadow: 0 0 15px rgba(0, 0, 0, 0.12);\n    border-radius: 8px;\n    height: max-content;\n    min-width: max-content;\n}\n\n.container-selects-configuracao {\n    display: flex;\n    flex-direction: column;\n    gap: 1rem;\n    width: 90%;\n    padding: .6rem 0;\n}\n\n/* Estilos data */\n.datas-container {\n    display: flex;\n    flex-direction: column;\n    width: 90%;\n    gap: .2rem;\n    padding: .6rem 0;\n    color: var(--azul-dixi);\n}\n\n.divisor-datas {\n    border: 1px solid var(--azul-dixi);\n    margin-bottom: .5rem;\n}\n\n.container-junto-data-e-hora {\n    display: flex;\n    gap: .5rem;\n}\n\n.prazo-final-texto {\n    display: flex;\n    align-items: center;\n    font-size: .8rem;\n    gap: .2rem;\n    color: var(--text-color2);\n    font-weight: 600;\n}\n\n.prazo-final-texto svg {\n    font-size: 1.1rem;\n}\n\n/* Configurações botões de canclear e criar */\n.botoes-config-container {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    width: 100%;\n    padding: 1rem 1.5rem;\n    gap: .8rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
