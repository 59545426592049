const validaCNPJ = (cnpj) => {
    // Remove os caracteres especiais (ponto, hífen, barra, etc.)
    cnpj = cnpj.replace(/[^\d]+/g, '');

    // Verifica se o CNPJ tem 14 dígitos
    if (cnpj.length !== 14) {
        return false;
    }

    // Verifica se todos os dígitos são iguais (caso comum de CNPJs inválidos)
    if (/^(\d)\1{13}$/.test(cnpj)) {
        return false;
    }

    // Validação do primeiro dígito verificador
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) {
            pos = 9;
        }
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== parseInt(digitos.charAt(0))) {
        return false;
    }

    // Validação do segundo dígito verificador
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) {
            pos = 9;
        }
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== parseInt(digitos.charAt(1))) {
        return false;
    }

    return true;
}

const validaCPF = (cpf, obrigatorio) => {
    if(!obrigatorio) {
        if(cpf === null || cpf === undefined || cpf === '') {
            return true;
        }
    }

    // Remove os caracteres especiais (ponto, hífen, etc.)
    cpf = cpf.replace(/[^\d]+/g, '');

    // Verifica se o CPF tem 11 dígitos
    if (cpf.length !== 11) {
        return false;
    }

    // Verifica se todos os dígitos são iguais (caso comum de CPFs inválidos)
    if (/^(\d)\1{10}$/.test(cpf)) {
        return false;
    }

    // Validação do primeiro dígito verificador
    let soma = 0;
    for (let i = 0; i < 9; i++) {
        soma += parseInt(cpf.charAt(i)) * (10 - i);
    }
    let resto = 11 - (soma % 11);
    if (resto === 10 || resto === 11) {
        resto = 0;
    }
    if (resto !== parseInt(cpf.charAt(9))) {
        return false;
    }

    // Validação do segundo dígito verificador
    soma = 0;
    for (let i = 0; i < 10; i++) {
        soma += parseInt(cpf.charAt(i)) * (11 - i);
    }
    resto = 11 - (soma % 11);
    if (resto === 10 || resto === 11) {
        resto = 0;
    }
    if (resto !== parseInt(cpf.charAt(10))) {
        return false;
    }

    return true;
}

const validaDocumento = (tipoDocumento, documento) => {

    const tipoDoc = tipoDocumento.idTipoDocumento;
    let documentoValido = false;

    if(validaCampoVazio(tipoDocumento) && validaCampoVazio(documento)) {
        if(tipoDoc === 1) {
            documentoValido = validaCPF(documento);
        } else if(tipoDoc === 2) {
            documentoValido = validaCNPJ(documento);
        }
    }

    return documentoValido;
}

const validaCampoVazio = (campo) => {

    let campoValido = false;
    if(campo !== undefined 
        && campo !== '' 
        && campo !== null) {

        campoValido = true;
    }

    return campoValido;
}

const validaSigla = (sigla) => {
    let siglaValida = false;

    if(validaCampoVazio(sigla)) {
        if(sigla.length > 1 && sigla.length < 5) {
            siglaValida = true;
        }
    }

    return siglaValida;
}

export { validaDocumento, validaCampoVazio, validaSigla, validaCPF };