// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-erros-em-campos {
    margin-top: 2rem;
}

.mensagem-erro-campos-invalidos {
    text-align: center;
    color: var(--vermelho-padrao);
    font-size: .9rem;
    font-size: 1rem;
    font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/components/CamposInvalidos/CamposInvalidos.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,6BAA6B;IAC7B,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB","sourcesContent":[".container-erros-em-campos {\n    margin-top: 2rem;\n}\n\n.mensagem-erro-campos-invalidos {\n    text-align: center;\n    color: var(--vermelho-padrao);\n    font-size: .9rem;\n    font-size: 1rem;\n    font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
