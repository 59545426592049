import { encryptData, decryptData } from '../utils/CryptoUtils';

export const recuperarCache = (chave) => {
    const cache = localStorage.getItem(chave);

    if (cache) {
        return decryptData(cache);
    }

    return null;
};

export const recuperarCacheSemDescriptografia = async (chave) => {
    return localStorage.getItem(chave);
};

export const salvarCache = (chave, valor) => {
    const encryptedValue = encryptData(valor);
    localStorage.setItem(chave, encryptedValue);
};

export const salvarCacheSemCriptografia = (chave, valor) => {
    localStorage.setItem(chave, valor);
};

export const limparCache = (chave) => {
    localStorage.removeItem(chave);
};

export const limparTodosCaches = () => {
    localStorage.clear();
};