import './BotaoPadrao.css';

function BotaoPadrao({
    acaoDeClicar,
    texto,
    confirmar,
    cancelar,
    disabled,
    classCustom
}) {

    return (
        <button 
            className={
                `${confirmar ? "botao-padrao-confirmar" : ''}
                ${cancelar ? "botao-padrao-cancelar" : ''}
                botao-padrao
                ${disabled ? "botao-padrao-disabled" : ''} 
                ${classCustom}`
            }
            disabled={disabled}
            onClick={acaoDeClicar}
            >
            {texto.toUpperCase()}
        </button>
    )
}

export default BotaoPadrao;