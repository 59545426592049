// Função para verificar para qual tipo de filtro o usuário está pesquisando
export const verificaTipoFiltro = (filtro) => {
    if (filtro.startsWith("#")) {
        return "id";
    } else if(filtro.startsWith("@")) {
        return "departamento";
    } else if(filtro.startsWith("-")) {
        return "descricao";
    } else if(filtro.startsWith("?")) {
        return "sistema";
    } else {
        return "assunto";
    }
};

export const retornaFiltro = (filtro) => {
    const filtroLower = filtro.toLowerCase()

    if(filtroLower === "" || filtroLower === undefined || filtroLower === null) {
        return "";
    }

    if(filtroLower.startsWith("#") 
        || filtroLower.startsWith("@")
        || filtroLower.startsWith("-")
        || filtroLower.startsWith("?")) {
        return filtroLower.substring(1);
    }

    return filtroLower;
};