import React from 'react';
import './PaginacaoPadrao.css';
import { HiChevronDoubleLeft } from "react-icons/hi";
import { HiChevronLeft } from "react-icons/hi";
import { HiChevronRight } from "react-icons/hi";
import { HiChevronDoubleRight } from "react-icons/hi";

const PaginacaoPadrao = ({ page, setCurrentPage, lastPage, loading }) => {

    const handlePage = (pageNumber) => {
        if((pageNumber < 0 || pageNumber >= lastPage) || loading) {
            return;
        }

        setCurrentPage(pageNumber);
    };

    const getPages = () => {
        const pages = [];
        const maxPagesToShow = 5;
        let startPage = Math.max(0, page - Math.floor(maxPagesToShow / 2));
        let endPage = startPage + maxPagesToShow;

        if (endPage > lastPage) {
            endPage = lastPage;
            startPage = Math.max(0, endPage - maxPagesToShow);
        }

        for (let i = startPage; i < endPage; i++) {
            pages.push(i);
        }
        return pages;
    };

    return (
        <div className="container-paginacao">
            <HiChevronDoubleLeft
                onClick={() => handlePage(0)}
                className={`voltar-duplo-paginacao seta-padrao-paginacao ${
                    page === 0 ? 'paginacao-indisponivel' : ''
                }`}
            />
            <HiChevronLeft
                onClick={() => handlePage(page - 1)}
                className={`seta-padrao-paginacao ${
                    page === 0 ? 'paginacao-indisponivel' : ''
                }`}
            />

            <div className="container-numeros-paginacao">
                {getPages().map((pageNumber) => (
                    <p
                        key={pageNumber}
                        onClick={() => handlePage(pageNumber)}
                        className={`numero-pagina ${pageNumber === page ? 'pagina-ativa' : ''}`}
                    >
                        {pageNumber + 1}
                    </p>
                ))}
            </div>

            <HiChevronRight
                onClick={() => handlePage(page + 1)}
                className={`seta-padrao-paginacao ${
                    page === lastPage - 1 ? 'paginacao-indisponivel' : ''
                }`}
            />
            <HiChevronDoubleRight
                onClick={() => handlePage(lastPage - 1)}
                className={`voltar-duplo-paginacao seta-padrao-paginacao ${
                    page === lastPage - 1 ? 'paginacao-indisponivel' : ''
                }`}
            />
        </div>
    );
};

export default PaginacaoPadrao;
