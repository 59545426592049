import './CustomSelectAdvanced.css'
import { useEffect, useState } from 'react';
import CampoObrigatorio from '../CampoObrigatorio/CampoObrigatorio';
import { IoChevronDown, IoChevronUp } from "react-icons/io5";
import NotifyUtils from '../../utils/NotifyUtils';

function CustomSelectAdvanced({
    opcoes, 
    titulo, 
    tipoSelect, 
    required,
    handleChange,
    extra,
    tipoExtra,
    opcaoInicial,
    defaultOption,
    defaultOptionFull,
    podeEscolher,
    msgVazio,
    naoAtualiza,
    avisoVazio
}) {

    const [cimaBaixo, setCimaBaixo] = useState(false);
    const [textoOpcaoEscolhida, setTextoOpcaoEscolhida] = useState(opcaoInicial ? defaultOption : "Selecione uma opção");

    useEffect(() => {
        if (opcaoInicial && defaultOption) {
            setTextoOpcaoEscolhida(defaultOption);
            if(handleChange && !naoAtualiza) {
                handleChange(tipoSelect, defaultOptionFull);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultOption, opcaoInicial]);

    const handleClick = () => {
        if(avisoVazio && opcoes.length === 0) {
            NotifyUtils.info(avisoVazio);
        } else {
            setCimaBaixo(!cimaBaixo);
        }
    }

    const handleOptionChange = (event) => {
        const selectedValue = event.target.value;
        const selectedText = event.target.dataset.label;
        setTextoOpcaoEscolhida(selectedText);

        setCimaBaixo(false);

        let opcaoCompleta = selectedValue;

        if(extra) {
            if(tipoExtra === "departamento") {
                for (let index = 0; index < extra.length; index++) {
                    const opcao = extra[index];
    
                    if(opcao.departamento.nomeDepartamento === selectedValue) {
                        opcaoCompleta = opcao;
                        break;
                    }
                }
            } else if(tipoExtra === "status") {
                for (let index = 0; index < extra.length; index++) {
                    const opcao = extra[index];
    
                    if(opcao.descricao === selectedValue) {
                        opcaoCompleta = opcao;
                        break;
                    }
                }
            } else if(tipoExtra === "usuario") {
                for (let index = 0; index < extra.length; index++) {
                    const opcao = extra[index];
    
                    if(opcao.nome === selectedValue) {
                        opcaoCompleta = opcao;
                        break;
                    }
                }
            }
        }

        if (handleChange) {
            handleChange(tipoSelect, opcaoCompleta);
        }
    }

    return (
        <div className={`select-container-general ${podeEscolher ? '' : 'inclicavel'}`}>
            <div id="custom-select-container">
                <label htmlFor="options-view-button" className={` ${cimaBaixo ? 'texto-azul' : ''} estilo-titulo-select`}>{titulo} {required ? <CampoObrigatorio /> : ''}</label>
                <input type="checkbox" id="options-view-button" onChange={handleClick} />
                    
                <div className={`${podeEscolher ? '' : 'inclicavel-campo'}`} id="select-button">
                    <div className="selected-value">{opcoes.length > 0 ? textoOpcaoEscolhida : (msgVazio ? msgVazio : 'Selecione uma opção')}</div>

                    <div className="chevrons">
                        {cimaBaixo ? <IoChevronUp /> : <IoChevronDown />}
                    </div>
                </div>
            </div>

            {opcoes.length > 0 ? 
                <ul className={cimaBaixo ? '' : 'nao-visivel'} id="options">
                    {opcoes.map((opcao, index) => (
                        <Opcao 
                            key={index} 
                            nomeCampo={tipoSelect} 
                            valor={opcao.descricao}
                            defaultChecked={opcao.descricao === defaultOption}
                            onChange={handleOptionChange}
                        />
                    ))}
                </ul> : ''
            }
        </div>
    );
}

const Opcao = ({ nomeCampo, valor, onChange, defaultChecked }) => {
    return (
        <li className="option">
            <input
                type="radio"
                name={nomeCampo}
                value={valor}
                data-label={valor}
                onChange={onChange}
                checked={defaultChecked}
            />

            <span className="label">{valor}</span>
        </li>
    );
}

export default CustomSelectAdvanced;