import AbrirChamadoContainer from "../components/AbrirChamadoContainer/AbrirChamadoContainer";
import Header from "../components/Header/Header";
import MenuLateral from "../components/MenuLateral/MenuLateral";
import { TicketProvider } from "../context/TicketContext";

const AbrirChamado = () => {
    return (
        <TicketProvider>
            <div className="main-container">
                <Header />
                <MenuLateral telaAtual={"abrir-chamado"} />
                <AbrirChamadoContainer />
            </div>
        </TicketProvider>
    );
}

export default AbrirChamado;