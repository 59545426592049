// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ativo-inativo-texto {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    width: 100%;
    height: 2rem;
    background-color: red;
    font-weight: bold;
    max-width: 10rem;
}

.ativo-inativo-texto.sit-1 {
    background-color: var(--verde-desativado);
    color: var(--texto-verde);
}

.ativo-inativo-texto.sit-2 {
    background-color: var(--vermelho-desativado);
    color: var(--texto-vermelho);
}`, "",{"version":3,"sources":["webpack://./src/components/SituacaoCadastro/SituacaoCadastro.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,qBAAqB;IACrB,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,yCAAyC;IACzC,yBAAyB;AAC7B;;AAEA;IACI,4CAA4C;IAC5C,4BAA4B;AAChC","sourcesContent":[".ativo-inativo-texto {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 8px;\n    width: 100%;\n    height: 2rem;\n    background-color: red;\n    font-weight: bold;\n    max-width: 10rem;\n}\n\n.ativo-inativo-texto.sit-1 {\n    background-color: var(--verde-desativado);\n    color: var(--texto-verde);\n}\n\n.ativo-inativo-texto.sit-2 {\n    background-color: var(--vermelho-desativado);\n    color: var(--texto-vermelho);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
