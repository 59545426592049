import './ListaComentarios.css';
import { useAuth } from '../../services/AuthContext';
import { localDateTimeArrToStringFormatado } from '../../utils/DataUtils';

function ListaComentarios({ comentarios }) {

    const { user } = useAuth();

    const tipoPadrao = "PÚBLICO";

    if (comentarios === undefined || comentarios === null) return <div>Loading...</div>;

    let temComentario = comentarios.length > 0;
    if(!temComentario) return null;

    // Ordena os comentários pela data de criação do mais novo para o mais antigo
    const comentariosOrdenados = [...comentarios].sort((a, b) => b.idComentario - a.idComentario);

    return (
        <div className="lista-comentarios-container">
            <ul className="lista-comentarios-do-chamado">
                {comentariosOrdenados.map((comentario) => (
                    <Comentario key={comentario.idComentario} idUserLogado={user.idUsuario} comentario={comentario} visibilidade={tipoPadrao} />
                ))}
            </ul>
        </div>
    );
}

const Comentario = ({ comentario, idUserLogado, visibilidade }) => {
    const textoComentario = comentario.descricao;
    const nomeUsuario = comentario.usuarioComentario.nome;

    const isProprio = comentario.usuarioComentario.idUsuario === idUserLogado;
    const dataComentario = localDateTimeArrToStringFormatado(comentario.dataCriacao);

    let temImagens = comentario.imagens.length > 0;

    return (
        <li className="comentario-chamado">
            <div className="avatar-usuario-comentario">
                {/* Simula uma foto de usuário */}
                <img src="https://www.w3schools.com/howto/img_avatar.png" alt="Avatar" className="avatar-usuario-comentario" />
            </div>

            <div id={`${isProprio ? 'is-proprio' : ''}`} className={`info-usuario-e-texto-container`}>
                <h3 className={`nome-usuario-comentario ${isProprio ? 'is-proprio-texto' : ''}`} >{nomeUsuario}</h3>
                <p className="conteudo-comentario-chamado">{textoComentario}</p>
                {temImagens 
                    && <ImagensSelecionadasVisChamado
                            imagens={[comentario.imagens]}
                            onRemove={null}
                />}
                <div id={`detalhe-comentario-container`}>
                    {isProprio && <span id="tipo-comentario">{visibilidade}</span>}
                    <span className="estilo-data-comentario" id={`data-comentario${isProprio ? '-proprio' : ''}`}>{dataComentario}</span>
                </div>
            </div>
        </li>
    );
}

const ImagensSelecionadasVisChamado = ({
    imagens,
    onRemove,
}) => {

    const direcionaParaImagem = (url) => {
        window.open(url, "_blank");
    }

    return (
        <div className="imagens-adicionadas-pelo-usuario-vis-chamado">
            {imagens[0].map((imagem, index) => (
                <div key={index} className="container-imagem-selecionada-vis-chamado">
                    <img className="imagem-selecionada-pelo-usuario-chamado-vis-chamado" onClick={() => {direcionaParaImagem(imagem.enderecoImagem)}} src={imagem.enderecoImagem} alt="Arquivo Selecionado" />
                </div>
            ))}
        </div>
    )
}

export default ListaComentarios;
