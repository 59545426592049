import './FormEditarUsuario.css'
import HeaderContainers from '../HeaderContainers/HeaderContainers';
import InputPadrao from '../InputPadrao/InputPadrao';
import InputSelectPadrao from '../InputSelectPadrao/InputSelectPadrao';
import { IoMdMail } from "react-icons/io";
import { FaUser } from "react-icons/fa";
import { IoDocumentText, c } from "react-icons/io5";
import { MdDeleteForever } from "react-icons/md";
import { RiErrorWarningFill } from "react-icons/ri";
import InputSenha from '../InputSenha/InputSenha';
import { useContext, useEffect, useState } from 'react';
import { getSituacaoCadastro } from '../../services/situacaoCadastroService';
import BotaoPadrao from '../BotaoPadrao/BotaoPadrao';
import { getPerfilUsuario } from '../../services/perfilUsuarioService';
import { VscUngroupByRefType } from "react-icons/vsc";
import { getDepartamentoEmpresa } from '../../services/departamentoEmpresaService';
import { useAuth } from '../../services/AuthContext';
import { getUsuarioById } from '../../services/usuarioService';
import { EditarUsuarioContext } from '../../context/EditarUsuarioContext';
import { recuperarCache, salvarCache } from '../../services/cacheHandler';
import NotifyUtils from '../../utils/NotifyUtils';
import CustomSelectAdvanced from '../CustomSelectAdvanced/CustomSelectAdvanced';
import { deleteDepartamentoEmpresaUsuarioByIdDepartamentoAndIdUsuario, getDepartamentoEmpresaUsuarioByIdUsuario } from '../../services/departamentoEmpresaUsuarioService';

function FormEditarUsuario({ idUsuario }) {

    const { user } = useAuth();

    const { usuarioData, setUsuarioData, handleChange, handleCancelling, handleSubmit,setDepartamento, handleSubmitDpto, setDepartamentoEmpresaUsuarioVinculado, listaDepartamentosTela, setListaDepartamentosTela } = useContext(EditarUsuarioContext);

    useEffect(() => {
        if(usuarioData === null || usuarioData === undefined || usuarioData.idUsuario === 0) {
            getUsuarioById(idUsuario).then((usuario) => {
                setUsuarioData(usuario);
            }).catch((error) => {
                console.error(error);
            });
        }
    }, []);

    const [departamentos, setDepartamentos] = useState(null);
    useEffect(() => {
        getDepartamentoEmpresa().then((departamento) => {
            setDepartamentos(departamento);
        }).catch((error) => {
            console.error(error);
        });
    }, []);

    useEffect(() => {
        getDepartamentoEmpresaUsuarioByIdUsuario(idUsuario)
            .then((listDepartamentoEmpresaUsuarioEncontrado) => {
                setDepartamentoEmpresaUsuarioVinculado(listDepartamentoEmpresaUsuarioEncontrado[0]);
                let listaIdDepartamentosVinculados = [];
                const novaLista = listDepartamentoEmpresaUsuarioEncontrado.map((dptoEmpresaUsuario) => {
                    listaIdDepartamentosVinculados.push(dptoEmpresaUsuario.departamento.idDepartamento);
                    return dptoEmpresaUsuario.departamento;
                });
                setListaDepartamentosTela(novaLista);
            }).catch((error) => {
                console.error(error);
            });
    }, [idUsuario, setDepartamentoEmpresaUsuarioVinculado, setDepartamento]);

    const textoCarregando = "carregando...";
    const textoConfirmar = "confirmar edição";
    const textoConfirmarDepartamento = "criar vínculo";
    const [botaoDesabilitado, setBotaoDesabilitado] = useState(false);
    const [mensagemBotaoConfirmar, setMensagemBotaoConfirmar] = useState(textoConfirmar);
    const [mensagemBotaoConfirmarDepartamento, setMensagemBotaoConfirmarDepartamento] = useState(textoConfirmarDepartamento);

    const [ nomeValido, setNomeValido ] = useState(true);
    const [ emailValido, setEmailValido ] = useState(true);
    const [ senhaValida, setSenhaValida ] = useState(true);
    const [ confirmarSenhaValida, setConfirmarSenhaValida ] = useState(true);
    const [ cpfValido, setCpfValido ] = useState(true);
    const [ situacaoCadastroValida, setSituacaoCadastroValida ] = useState(true);
    const [ perfilUsuarioValido, setPerfilUsuarioValido ] = useState(true);

    const handleCancelar = () => {
        handleCancelling();
    }

    const [situacaoCadastro, setSituacaoCadastro] = useState(null);
    useEffect(() => {
        getSituacaoCadastro().then((situacaoCadastro) => {
            setSituacaoCadastro(situacaoCadastro);
        }).catch((error) => {
            console.error(error);
        });
    }, []);

    const [perfilUsuario, setPerfilUsuario] = useState(null);
    useEffect(() => {
        getPerfilUsuario().then((perfilUsuario) => {
            setPerfilUsuario(perfilUsuario);
        }).catch((error) => {
            console.error(error);
        });
    }, []);

    let situacoesCadastro = [];
    let perfisUsuarios = [];
    let dptos = [];
    if (situacaoCadastro !== null) {
        situacoesCadastro = situacaoCadastro;
    } else {
        return <div>Loading...</div>;
    }

    if (perfilUsuario !== null) {
        perfisUsuarios = perfilUsuario;
    } else {
        return <div>Loading...</div>;
    }

    if (departamentos !== null) {
        dptos = departamentos;
    } else {
        return <div>Loading...</div>;
    }

    if (usuarioData === null || usuarioData.idUsuario === undefined || usuarioData.idUsuario === 0) {
        return <div>Loading...</div>;
    }

    const handleRemoverDepartamento = (departamento) => {
        if(listaDepartamentosTela.length <= 1) {
            NotifyUtils.erro("O usuário deve ter pelo menos um departamento vinculado.");
            return;
        }

        deleteDepartamentoEmpresaUsuarioByIdDepartamentoAndIdUsuario(departamento.idDepartamento, idUsuario)
        .then(() => {
            setListaDepartamentosTela(prevList => prevList.filter(dep => dep.idDepartamento !== departamento.idDepartamento));
            NotifyUtils.sucesso("Departamento removido com sucesso");
        })
        .catch(error => {
            NotifyUtils.erro("Erro ao remover departamento");
            console.error(error);
        });
    };

    const handleVincularDepartamento = async (e) => {
        // Assim que confirmar, desabilita o botão e muda o texto para "carregando..."
        e.preventDefault();
        setBotaoDesabilitado(true);
        setMensagemBotaoConfirmarDepartamento(textoCarregando);

        const retornoRequest = await handleSubmitDpto();

        if(retornoRequest === null) {
            NotifyUtils.info("Atualizando...");
            return;
        }

        if(retornoRequest.success) {
            NotifyUtils.sucesso(retornoRequest.message);

            setTimeout(() => {
                setBotaoDesabilitado(false);
                setMensagemBotaoConfirmarDepartamento(textoConfirmarDepartamento);
            }, 1000);
            return;
        } else {
            NotifyUtils.erro(retornoRequest.message);
        }
    };

    const clickBotaoConfirmar = async (e) => {
        // Assim que confirmar, desabilita o botão e muda o texto para "carregando..."
        e.preventDefault();
        setBotaoDesabilitado(true);
        setMensagemBotaoConfirmar(textoCarregando);

        // Faz o envio de informações para o back caso esteja tudo certo com os campos, se não tiver, retorna os campos inválidos
        const retornoRequest = await handleSubmit();

        if(retornoRequest === null) {
            NotifyUtils.info("Nenhuma informação foi alterada!");
            setBotaoDesabilitado(false);
            setMensagemBotaoConfirmar(textoConfirmar);
            return;
        }

        const erroCustom = retornoRequest.success === undefined;
    
        if(!retornoRequest.success && !erroCustom) {
            NotifyUtils.erro(retornoRequest.message);
        } else if(retornoRequest.success) {
            NotifyUtils.sucesso(retornoRequest.message);

            if(user.idUsuario === usuarioData.idUsuario) {
                let usuarioLogadoCache = recuperarCache('user');

                usuarioLogadoCache.nome = usuarioData.nome;
                usuarioLogadoCache.email = usuarioData.email;

                salvarCache('user', usuarioLogadoCache);
            }

            setNomeValido(true);
            setEmailValido(true);
            setSenhaValida(true);
            setConfirmarSenhaValida(true);
            setCpfValido(true);
            setSituacaoCadastroValida(true);
            setPerfilUsuarioValido(true);

            setTimeout(() => {
                setBotaoDesabilitado(false);
                setMensagemBotaoConfirmar(textoConfirmar);
            }, 2500);
            return;
        } else if(erroCustom) {
            NotifyUtils.erro(retornoRequest.message);

            // Atualiza status dos campos inválisdos
            const camposInvalidos = retornoRequest.camposInvalidos;

            setNomeValido(camposInvalidos.nomeValido);
            setEmailValido(camposInvalidos.emailValido);
            setSenhaValida(camposInvalidos.senhaValida);
            setConfirmarSenhaValida(camposInvalidos.confirmarSenhaValida);
            setCpfValido(camposInvalidos.cpfValido);
            setSituacaoCadastroValida(camposInvalidos.situacaoCadastroValida);
            setPerfilUsuarioValido(camposInvalidos.perfilUsuarioValido);
        }

        setBotaoDesabilitado(false);
        setMensagemBotaoConfirmar(textoConfirmar);
    }

    return (
        <div className="container-edicao-usuario">
            <div className="container-geral-cadastro-usuario">
                <HeaderContainers texto="editar usuário" />

                <div className="formulario-edicao-nova-usuario">
                    <form>
                        <div className="container-principal-infos-usuario">
                            <div className="container-info-principal-usuario">
                                <div className="container-nome-email-cadastro-usuarios">
                                    <InputPadrao
                                        titulo="NOME"
                                        icone={<FaUser />}
                                        obrigatorio={true}
                                        name={"nome-usuario"}
                                        onChange={handleChange}
                                        placeholder={"Insira o nome do usuário."}
                                        inputPara={"nome"}
                                        comErro={!nomeValido}
                                        valorInicial={usuarioData.nome}
                                    />
                                    <InputPadrao
                                        titulo="E-MAIL"
                                        icone={<IoMdMail />}
                                        obrigatorio={true}
                                        onChange={handleChange}
                                        name={"email-usuario"}
                                        placeholder={"Insira o email do usuário."}
                                        inputPara={"email"}
                                        comErro={!emailValido}
                                        valorInicial={usuarioData.email}
                                    />
                                    <InputPadrao
                                        titulo="NÚMERO DO CPF"
                                        icone={<IoDocumentText />}
                                        name={"cpf-usuario"}
                                        placeholder={"Insira o número do documento."}
                                        onChange={handleChange}
                                        mask="cpf"
                                        inputPara={"cpf"}
                                        comErro={!cpfValido}
                                        valorInicial={usuarioData.cpf}
                                    />
                                </div>
                            </div>

                            <div className="container-tipo-usuario-cadastro-usuario">
                                <InputSelectPadrao
                                    titulo={"TIPO USUÁRIO"}
                                    icone={<VscUngroupByRefType />}
                                    name={"tipo-usuario"}
                                    onChange={handleChange}
                                    placeholder={"Insira o tipo do usuário"}
                                    inputPara={"perfilUsuario"}
                                    options={perfisUsuarios}
                                    obrigatorio={true}
                                    comErro={!perfilUsuarioValido}
                                    valorInicial={usuarioData.perfilUsuario.descricao}
                                />

                                <InputSelectPadrao
                                    titulo="SITUAÇÃO DO CADASTRO"
                                    icone={<RiErrorWarningFill />}
                                    name={"situacao-cadastro"}
                                    onChange={handleChange}
                                    placeholder={"Selecione a situação."}
                                    options={situacoesCadastro}
                                    obrigatorio={true}
                                    inputPara={"situacaoCadastro"}
                                    comErro={!situacaoCadastroValida}
                                    valorInicial={usuarioData.situacaoCadastro.descricao}
                                />
                            </div>
                        </div>

                        <div className="container-senha-cadastro-usuario">
                            <div className="titulo-container-senha">
                                <h3 className="titulo-senha-cadastro-usuario">SENHA</h3>
                                <hr className="divisor-top-container" />
                            </div>

                            <div className="container-senha-confirma-senha">
                                <InputSenha
                                    placeholder={"SENHA"}
                                    tipoInput={"senha"}
                                    onChange={handleChange}
                                    inputPara={"senha"}
                                    comErro={!senhaValida}
                                />
                                <InputSenha
                                    placeholder={"CONFIRMAR SENHA"}
                                    tipoInput={"confirmarSenha"}
                                    onChange={handleChange}
                                    inputPara={"confirmarSenha"}
                                    comErro={!confirmarSenhaValida}
                                />
                            </div>
                        </div>
                    </form>

                    <div className="container-botoes-cadastro-usuario">
                        <BotaoPadrao
                            texto={"VOLTAR"}
                            cancelar={true}
                            acaoDeClicar={handleCancelar}
                        />

                        <BotaoPadrao
                            texto={mensagemBotaoConfirmar}
                            confirmar={true}
                            acaoDeClicar={clickBotaoConfirmar}
                            disabled={botaoDesabilitado}
                        />
                    </div>
                </div>
            </div>

            <div className="container-adicional-cadastro-usuario">
                <HeaderContainers texto="departamentos vinculados" />

                <div className="container-departamentos-vinculados">

                    <ul className="departamentos-vinculados-list">
                        {listaDepartamentosTela.length > 0 ? listaDepartamentosTela.map((dpto, index) => {
                            return <DepartamentoVinculado onRemove={handleRemoverDepartamento} key={dpto.idDepartamento} departamento={dpto} />
                        }) : <li className="departamento-vinculado-item">Nenhum departamento vinculado.</li>}
                    </ul>

                    <CustomSelectAdvanced
                        opcoes={converteDptoParaSelect(dptos, listaDepartamentosTela)}
                        titulo={"VINCULAR NOVO DEPARTAMENTO"}
                        icone={<IoDocumentText />}
                        tipoSelect={"departamento"}
                        required={false}
                        handleChange={handleChange}
                        extra={dptos}
                        tipoExtra={"departamento"}
                        podeEscolher={true}
                    />

                    <BotaoPadrao
                        texto={mensagemBotaoConfirmarDepartamento}
                        confirmar={true}
                        acaoDeClicar={handleVincularDepartamento}
                        disabled={botaoDesabilitado}
                    />
                </div>
            </div>
            
        </div>
    )
}

const converteDptoParaSelect = (departamentos, listaDptoTela) => {
    const dptosVinculados = listaDptoTela.map((dpto) => dpto.idDepartamento);

    let listaRetorno = [];

    for(let i = 0; i < departamentos.length; i++) {
        const dpto = departamentos[i].departamento;
        if(dptosVinculados.includes(dpto.idDepartamento)) {
            continue;
        }
        listaRetorno.push({
            id: dpto.deidDepartamento,
            descricao: dpto.nomeDepartamento
        });
    }

    return listaRetorno;
}

const DepartamentoVinculado = ({ departamento, onRemove }) => {
    return (
        <li className="departamento-vinculado-item">{departamento.nomeDepartamento} <span><MdDeleteForever onClick={() => {onRemove(departamento)}} /></span></li>
    )
}

export default FormEditarUsuario;