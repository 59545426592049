import { montaUrlFiltroPaginacaoEmpresa } from "../utils/DataUtils";
import api from "./api";

const endpoint = "/empresa_";
const PAGE_SIZE = 5;

export const createEmpresa = async (empresa) => {
    try {
        const response = await api.post(endpoint, empresa, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        return response.data;
    } catch (error) {
        if (error.response) {
            const { status, data } = error.response;
            if (status === 409) {
                return data;
            } else {
                return null;
            }
        } else {
            return null;
        }
    }
}

export const getEmpresas = async (page) => {
    try {
        const response = await api.get(endpoint + "?page=" + page + "&size=" + PAGE_SIZE);
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
}

export const getEmpresasFiltradas = async (empresaFilter, page) => {
    try {
        const response = await api.get(montaUrlFiltroPaginacaoEmpresa(endpoint, empresaFilter, page, PAGE_SIZE));
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
}

export const getEmpresaById = async (idEmpresa) => {
    try {
        const response = await api.get(endpoint + `/${idEmpresa}`);
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
}

export const updateEmpresa = async (empresa) => {
    try {
        const response = await api.put(endpoint + `/${empresa.idEmpresa}`, empresa, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
}