import MenuLateral from "../components/MenuLateral/MenuLateral";
import Header from "../components/Header/Header";
import DepartamentoContainer from "../components/DepartamentoContainer/DepartamentoContainer";
import { DepartamentoFilterProvider } from "../context/DepartamentoFilterContext";

export default function Departamentos() {
  return (
    <div className="main-container">
      <DepartamentoFilterProvider>
        <Header />
        <MenuLateral telaAtual={"departamentos"} />
        <DepartamentoContainer />
      </DepartamentoFilterProvider>
    </div>
  )
}
