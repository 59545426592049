import { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { updateChamado } from '../services/ticketService'; 
import { getDepartamentoEmpresa } from '../services/departamentoEmpresaService';
import { getUsuarioByDepartamentoEmpresaList } from '../services/usuarioService';
import { montaResposta } from '../utils/ResponsesUtils';
import NotifyUtils from '../utils/NotifyUtils';
import { getImagem } from '../services/imagemService';
import { useAuth } from '../services/AuthContext';

export const EditTicketContext = createContext();

export const EditTicketProvider = ({ children }) => {

  const { user } = useAuth();
  const navigate = useNavigate();

  const [ticketData, setTicketData] = useState({
    usuarioResponsavel: '',
    usuarioResponsavelAtual: '',
    departamentoEmpresa: '',
    status: '',
    descricao: '',
    sistema: {
      idSistema: 1,
      descricao: "Sistema"
    },
    imagens: [],
    anexos: [],
  });

  const [ initState, setInitState ] = useState(true);
  const [ atualizou, setAtualizou ] = useState(false);
  const [ podeSalvar, setPodeSalvar ] = useState(true);
  const [ departamentoEscolhido, setDepartamentoEscolhido ] = useState(null);
  const [ departamentosDisponiveis, setDepartamentosDisponiveis ] = useState([]);
  const [ usuariosDisponiveis, setUsuariosDisponiveis ] = useState([]);
  const [ podeEditarEsseChamado, setPodeEditarEsseChamado ] = useState(null);

  const [ imagensParaSalvar, setImagensParaSalvar ] = useState([]);
  const [ anexosParaSalvar, setAnexosParaSalvar ] = useState([]);

  useEffect(() => {
    getDepartamentoEmpresa().then((departamentosEncontrados) => {
      setDepartamentosDisponiveis(departamentosEncontrados);
    }).catch((error) => {
        console.error(error);
    });
  }, []);

  useEffect(() => {
    if(departamentoEscolhido === null || departamentoEscolhido === undefined) return;

    // Função para pegar todos os usuários disponíveis com base no departamento escolhido
    getUsuarioByDepartamentoEmpresaList(departamentoEscolhido).then((usuariosEncontrados) => {
      setUsuariosDisponiveis(usuariosEncontrados);

      if(usuariosEncontrados.length > 0) {
        setPodeSalvar(true);

        if(initState) {
          setInitState(false);
        } else {
          handleChange('usuarioResponsavelAtual', usuariosEncontrados[0]);
        }
      } else {
        // Se não tiver usuário disponível, impossibilita a edição
        setPodeSalvar(false);
      }

    }).catch((error) => {
        console.error(error);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departamentoEscolhido]);

  // Função para atualizar o estado do ticket (um valor pro vez)
  const handleChange = (field, value) => {
    if(value === undefined) return;
    setTicketData(estadoAnterior => ({
      ...estadoAnterior,
      [field]: value,
    }));

    if(field === 'departamentoEmpresa') {
      setDepartamentoEscolhido(value);
    }
  };

  const adicionaComentario = (comentario) => {
    setTicketData(estadoAnterior => ({
      ...estadoAnterior,
      comentarios: [
        ...estadoAnterior.comentarios,
        comentario
      ]
    }));
  }

  const verificaSePodeEditarEsseChamado = (userChamado) => {
    // DEIXAR ESSA VALIDAÇÃO MAIS ROBUSTA (regra de negócio)
    setPodeEditarEsseChamado(true);

    // setPodeEditarEsseChamado((user.idUsuario === userChamado.idUsuario) || user.idUsuario === 1);
  }

  const getTotalArquivos = () => {
    const totalArquivos = imagensParaSalvar.length + anexosParaSalvar.length;
    return totalArquivos;
  };

  const handleSubmit = async () => {
    if(!podeSalvar) {
      return montaResposta(
        false, 
        'Não é possível salvar o chamado sem um usuário responsável!',
        null,
        null);
    }

    const retorno = await updateChamado(ticketData, user.idUsuario);

    if(retorno) {
      return montaResposta(
        true, 
        'Chamado atualizado com sucesso!',
        null,
        null);
    } else {
      return montaResposta(
        false, 
        'Um erro aconteceu!',
        null,
        null);
    }
  }

  const handleAnexoDownload = async (url) => {
    NotifyUtils.aviso('Aguarde enquanto o download é feito...');
    // Faz a requisição para o s3 e baixa o arquivo
    const response = await getImagem(url);

    NotifyUtils.sucesso(response);
  };

  const handleCancelling = () => {
    handleClear();
    voltarTelaInicial();
  };

  const limpaImagens = () => {
    setImagensParaSalvar([]);
  }

  const handleClear = () => {
    setTicketData({
      usuarioResponsavel: '',
      usuarioResponsavelAtual: '',
      departamentoEmpresa: '',
      status: '',
      descricao: '',
      sistema: {
        idSistema: 1,
        descricao: "Sistema"
      },
      imagens: [],
      anexos: [],
    })
  }

  const voltarTelaInicial = () => {
    navigate('/');
  };

  const handleAddFile = (e, type) => {
    const file = e.target.files[0];
    if (!file) return;

    let tipo = '';
    switch (type) {
      case 'attachment':
        break;
      case 'imagens':
        tipo = 'imagens';
        break;
      default:
        return;
    }

    const reader = new FileReader();
    reader.onloadend = () => {
      const fileData = {
        file: file,
        url: reader.result,
      };

      if(tipo === 'imagens') {
        setImagensParaSalvar([...imagensParaSalvar, fileData]);
      } else {
        setAnexosParaSalvar([...anexosParaSalvar, fileData]);
      }
    };
    reader.readAsDataURL(file);
  };

  const handleFileRemove = (index, type) => {
    if(type === 'imagens') {
      const updatedFiles = [...imagensParaSalvar];
      updatedFiles.splice(index, 1);
      setImagensParaSalvar(updatedFiles);
    } else {
      const updatedFiles = [...anexosParaSalvar];
      updatedFiles.splice(index, 1);
      setAnexosParaSalvar(updatedFiles);
    }
  };

  return (
    <EditTicketContext.Provider value={{ 
      ticketData, 
      setTicketData, 
      adicionaComentario, 
      departamentosDisponiveis, 
      usuariosDisponiveis, 
      handleCancelling, 
      atualizou, 
      setAtualizou, 
      handleChange, 
      setDepartamentoEscolhido, 
      handleSubmit, 
      handleAnexoDownload,
      podeEditarEsseChamado, 
      verificaSePodeEditarEsseChamado, 
      handleAddFile,
      getTotalArquivos,
      imagensParaSalvar,
      anexosParaSalvar,
      handleFileRemove,
      limpaImagens
    }}
      >
      {children}
    </EditTicketContext.Provider>
  );
};