import api from "./api";

const endpoint = "/departamentoEmpresaUsuario_";

export const getDepartamentoEmpresaUsuario = async () => {
    try {
        const response = await api.get(endpoint);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export const getDepartamentoEmpresaUsuarioByIdUsuario = async (idUsuario) => {
    try {
        const response = await api.get(endpoint + `/byIdUsuario/${idUsuario}`);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export const createDepartamentoEmpresaUsuario = async (departamentoEmpresaUsuario) => {
    try {
        const response = await api.post(endpoint, departamentoEmpresaUsuario, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export const updateDepartamentoEmpresaUsuario = async (departamentoEmpresaUsuario) => {
    try {
        let id = departamentoEmpresaUsuario.idDepartamentoEmpresaUsuario;
        const response = await api.put(endpoint + `/${id}`, departamentoEmpresaUsuario, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export const deleteDepartamentoEmpresaUsuarioByIdDepartamentoAndIdUsuario = async (idDepartamento, idUsuario) => {
    try {
        await api.delete(endpoint + `/${idDepartamento}/${idUsuario}`);
    } catch (error) {
        console.error(error);
        return null;
    }
};