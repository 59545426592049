import api from "./api";

const endpoint = "/departamentoEmpresa_";

export const getDepartamentoEmpresa = async () => {
    try {
        const response = await api.get(endpoint);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export const getDepartamentoEmpresaByUsuario = async (idUsuario) => {

    if(idUsuario === 1) {
        return getDepartamentoEmpresa();
    }

    try {
        const response = await api.get(endpoint + `/byUsuario/${idUsuario}`);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export const createDepartamentoEmpresa = async (departamentoEmpresa) => {
    try {
        const response = await api.post(endpoint, departamentoEmpresa, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
}
