const sucesso = "success";
const mensagem = "message";
const conteudo = "data";
const invalidos = "camposInvalidos";

const montaResposta = (success, message, content, camposInvalidos) => {
    return {
        [sucesso]: success,
        [mensagem]: message,
        [conteudo]: content,
        [invalidos]: camposInvalidos
    }
}

export { montaResposta };