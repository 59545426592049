import FiltroEmpresaContainer from '../FiltroEmpresaContainer/FiltroEmpresaContainer';
import ListViewEmpresa from '../ListViewEmpresa/ListViewEmpresa';
import './EmpresasContainer.css';
import { getEmpresas, getEmpresasFiltradas } from '../../services/empresaService';
import { useEffect, useState, useContext } from 'react';
import { EmpresaFilterContext } from '../../context/EmpresaFilterContext';
import { useAuth } from '../../services/AuthContext';
import Loading from '../Loading/Loading';

function EmpresasContainer() {

  const { user } = useAuth();
  const [ loading, setLoading ] = useState(true);

  const { empresaFilter, filtrar, temFiltro } = useContext(EmpresaFilterContext);

  const [ page, setPage ] = useState(0);
  const [ infoPagina, setInfoPagina ] = useState({});

  const [ empresas, setEmpresas ] = useState([]);

  useEffect(() => {
    async function fetchEmpresas() {
      let response = null;
      setLoading(true);

      if(temFiltro()) {
        response = await getEmpresasFiltradas(empresaFilter, page);
      } else {
        response = await getEmpresas(page);
      }

      setEmpresas(response.content);
      setInfoPagina(response);
      setLoading(false);
    }

    fetchEmpresas();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, filtrar]);

  const podeCadastrar = user.podeCadastrarEmpresa;

  return (
    <div className="container">
        {loading && <Loading />}
        
        <FiltroEmpresaContainer 
          podeCadastrar={podeCadastrar}
        />

        <ListViewEmpresa
          empresas={empresas}
          page={page}
          infoPagina={infoPagina}
          setPage={setPage}
        />
    </div>
  )
}

export default EmpresasContainer;