import { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createChamado } from '../services/ticketService'; 
import { saveAllImagem } from '../services/imagemService'; 
import { validaCampoVazio } from '../validations/basicsValidations';
import { useAuth } from '../services/AuthContext';
import { montaResposta } from '../utils/ResponsesUtils';
import { getUsuarioByDepartamentoEmpresaList, getUsuarios } from '../services/usuarioService';
import NotifyUtils from '../utils/NotifyUtils';
import { getDepartamentoEmpresa } from '../services/departamentoEmpresaService';

export const TicketContext = createContext();

export const TicketProvider = ({ children }) => {

  const { user } = useAuth();
  const navigate = useNavigate();

  const [ loading, setLoading ] = useState(true);

  const [ticketData, setTicketData] = useState({
    usuarioResponsavel: '',
    usuarioResponsavelAtual: user,
    departamentoEmpresa: '',
    status: '',
    descricao: '',
    sistema: {
      idSistema: 1,
      descricao: "Sistema"
    },
    imagens: [],
    anexos: [],
  });

  const [departamentoEscolhido, setDepartamentoEscolhido] = useState(null);
  const [usuariosDisponiveis, setUsuariosDisponiveis] = useState([]);
  const [departamentosDisponiveis, setDepartamentosDisponiveis] = useState([]);

  useEffect(() => {
    setLoading(true);

    if (departamentoEscolhido === null) {
      getUsuarios().then((usuarios) => {
        setUsuariosDisponiveis(usuarios);
      }).catch((error) => {
        console.error(error);
      });
    } else {
      getUsuarioByDepartamentoEmpresaList(departamentoEscolhido).then((usuariosEncontrados) => {
        setUsuariosDisponiveis(usuariosEncontrados);
      }).catch((error) => {
        console.error(error);
      });
    }
  
    setLoading(false);
  }, [departamentoEscolhido]);

  useEffect(() => {
    getDepartamentoEmpresa().then((departamentos) => {
      setDepartamentosDisponiveis(departamentos);
    }).catch((error) => {
      console.error(error);
    })
  }, [])

  const handleChange = (field, value) => {
    setTicketData(prevState => ({
      ...prevState,
      [field]: value,
    }));

    if (field === 'departamentoEmpresa') {
      setDepartamentoEscolhido(value);
    }
  };

  const handleSubmit = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const responsavel = { idUsuario: user.idUsuario };
        const newTicketData = { ...ticketData, usuarioResponsavel: responsavel };
  
        const assuntoValido = validaCampoVazio(newTicketData.assunto);
        const descricaoValida = validaCampoVazio(newTicketData.descricao);
        const estadoValido = validaCampoVazio(newTicketData.status);
        const departamentoValido = validaCampoVazio(newTicketData.departamentoEmpresa);
        const usuarioResponsavelValido = validaCampoVazio(newTicketData.usuarioResponsavel);
        const usuarioResponsavelAtualValido = validaCampoVazio(newTicketData.usuarioResponsavelAtual);
  
        let mensagemSucesso = 'Chamado cadastrado com sucesso!';
  
        if (
          !assuntoValido ||
          !descricaoValida ||
          !estadoValido ||
          !departamentoValido ||
          !usuarioResponsavelValido ||
          !usuarioResponsavelAtualValido
        ) {
          return reject(
            montaResposta(
              undefined,
              'Preencha corretamente os campos obrigatórios.',
              null,
              {
                assuntoValido,
                descricaoValida,
                estadoValido,
                departamentoValido,
                usuarioResponsavelValido,
                usuarioResponsavelAtualValido,
              }
            )
          );
        }
  
        // Cria um objeto temporário apenas para enviar ao abrir chamado sem as imagens e anexos
        let ticketDataTemp = {
          ...newTicketData,
          imagens: [],
          anexos: [],
        };
        const chamadoSalvo = await createChamado(ticketDataTemp);
  
        if (chamadoSalvo) {
          ticketDataTemp = null;
          if (newTicketData.imagens.length > 0 || newTicketData.anexos.length > 0) {
            const imagensSalvas = await saveAllImagem(newTicketData.imagens, chamadoSalvo, newTicketData.anexos);
  
            if (imagensSalvas) {
              return resolve(montaResposta(true, mensagemSucesso, null, null));
            } else {
              return reject(montaResposta(false, 'Erro ao salvar imagens do chamado', null, null));
            }
          } else {
            return resolve(montaResposta(true, mensagemSucesso, null, null));
          }
        } else {
          return reject(montaResposta(false, 'Erro ao cadastrar chamado', null, null));
        }
      } catch (error) {
        console.error(error);
        return reject(montaResposta(false, 'Erro ao cadastrar chamado', null, null));
      }
    });
  };

  const handleCancelling = () => {
    handleClear();
    voltarTelaInicial();
  };

  const handleClear = () => {
    setTicketData({
      usuarioResponsavel: '',
      usuarioResponsavelAtual: user,
      departamentoEmpresa: '',
      status: '',
      sistema: {
        idSistema: 1,
        descricao: "padrao"
      },
      descricao: '',
      imagens: [],
      anexos: [],
    });
  };
  
  const handleFileChange = (e, type) => {
    if(e.target.files.length > 0) {
      const file = e.target.files[0];
      let extensaoDoArquivo = file.name.split('.').pop();
      
  
      if (!file) return;
  
      let tipo = '';
      switch (type) {
        case 'attachment':
          tipo = 'anexos';
          break;
        case 'imagens':
          tipo = 'imagens';
          break;
        default:
          return;
      }
  
      if(tipo === 'anexos') {
        let extensoesPermitidas = ["pdf", "docx", "txt", "csv"]
  
        if (file.size === 0) {
          NotifyUtils.aviso('Arquivo vazio não permitido!');
          return;
        } else if (file.size > 26214400) {
          NotifyUtils.aviso('Tamanho máximo de 25MB excedido!');
          return;
        }
        if (!extensoesPermitidas.includes(extensaoDoArquivo)) {
          NotifyUtils.aviso('Extensão de arquivo não permitida! Extensões permitidas: .pdf, .docx, .txt, .csv');
          return;
        }
      } 
  
      const reader = new FileReader();
      reader.onloadend = () => {
        setTicketData(prevState => ({
          ...prevState,
          [tipo]: [
            ...prevState[tipo],
            { file, url: reader.result }
          ]
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileRemove = (index, type) => {
    setTicketData(prevState => {
      const updatedFiles = Array.isArray(prevState[type]) ? [...prevState[type]] : []; 
      updatedFiles.splice(index, 1);
      return {
        ...prevState,
        [type]: updatedFiles
      };
    });
  };


  const voltarTelaInicial = () => {
    navigate('/');
  };

  return (
    <TicketContext.Provider
      value={{
        ticketData,
        handleFileRemove,
        handleChange,
        handleSubmit,
        handleCancelling,
        usuariosDisponiveis,
        handleFileChange,
        loading,
        setLoading,
        departamentosDisponiveis
      }}
    >
      {children}
    </TicketContext.Provider>
  );
};
