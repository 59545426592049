// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
    width: calc(100% - 1.1rem);
    padding-left: 5.1rem;
    padding-top: 3.9rem;
}`, "",{"version":3,"sources":["webpack://./src/components/Container/Container.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,oBAAoB;IACpB,mBAAmB;AACvB","sourcesContent":[".container {\n    width: calc(100% - 1.1rem);\n    padding-left: 5.1rem;\n    padding-top: 3.9rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
