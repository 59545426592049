// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo-banner {
    width: 250px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 2rem auto;
}

.logo-banner-img {
    width: 270px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
    filter: drop-shadow(-6px 5px 3px rgba(0, 0, 0, 0.07));
}`, "",{"version":3,"sources":["webpack://./src/components/LogoBanner/LogoBanner.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,wBAAwB;AAC5B;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;IACnB,qDAAqD;AACzD","sourcesContent":[".logo-banner {\n    width: 250px;\n    height: 150px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin: 0 auto 2rem auto;\n}\n\n.logo-banner-img {\n    width: 270px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-bottom: 2rem;\n    filter: drop-shadow(-6px 5px 3px rgba(0, 0, 0, 0.07));\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
