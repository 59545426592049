import './AbrirChamadoContainer.css';
import FormAbrirChamado from '../FormAbrirChamado/FormAbrirChamado';
import ConfigAbrirChamado from '../ConfigAbrirChamado/ConfigAbrirChamado';

function AbrirChamadoContainer() {
    return (
        <div className="container-abrir-chamado">
            <FormAbrirChamado />
            
            <ConfigAbrirChamado />
        </div>
    )
}

export default AbrirChamadoContainer