import { useContext, useEffect, useState } from 'react';
import HeaderContainers from '../HeaderContainers/HeaderContainers';
import './FormEditarEmpresa.css'
import { MdAccountBalance, MdContactPage, MdDescription, MdError } from "react-icons/md";
import { IoChatboxSharp } from "react-icons/io5";
import InputPadrao from '../InputPadrao/InputPadrao';
import InputSelectPadrao from '../InputSelectPadrao/InputSelectPadrao';
import BotaoPadrao from '../BotaoPadrao/BotaoPadrao';
import { getSituacaoCadastro } from '../../services/situacaoCadastroService';
import { getTipoDocumento } from '../../services/tipoDocumentoService';
import { ResponseUtils } from '../../utils/ResponsesUtils';
import CamposInvalidos from '../CamposInvalidos/CamposInvalidos';
import { EditarEmpresaContext } from '../../context/EditarEmpresaContext';
import { getEmpresaById } from '../../services/empresaService';
import NotifyUtils from '../../utils/NotifyUtils';

function FormEditarEmpresa({ idEmpresa }) {

    const { empresaData, setEmpresaData, handleCancelling, handleChange, handleSubmit } = useContext(EditarEmpresaContext);

    useEffect(() => {
        if(empresaData === null || empresaData === undefined || empresaData.idEmpresa === 0) {
            getEmpresaById(idEmpresa).then((empresa) => {
                setEmpresaData(empresa);
            }).catch((error) => {
                console.error(error);
            });
        }
    }, []);

    const textoCarregando = "carregando...";
    const textoConfirmar = "confirmar edição";

    const [situacaoCadastro, setSituacaoCadastro] = useState(null);
    const [tipoDocumento, setTipoDocumento] = useState(null);
    const [botaoDesabilitado, setBotaoDesabilitado] = useState(false);
    const [mensagemBotaoConfirmar, setMensagemBotaoConfirmar] = useState(textoConfirmar);

    const [ cnpjValido, setCnpjValido ] = useState(true);
    const [ razaoSocialValida, setRazaoSocialValida ] = useState(true);
    const [ siglaValida, setSiglaValida ] = useState(true);
    const [ situacaoCadastroValida, setSituacaoCadastroValida ] = useState(true);

    useEffect(() => {
        getSituacaoCadastro().then((situacaoCadastro) => {
            setSituacaoCadastro(situacaoCadastro);
        }).catch((error) => {
            console.error(error);
        });
    }, []);

    useEffect(() => {
        getTipoDocumento().then((tipoDocumento) => {
            setTipoDocumento(tipoDocumento);
        }).catch((error) => {
            console.error(error);
        });
    }, []);

    const clickBotaoConfirmar = async (e) => {
        // Assim que confirmar, desabilita o botão e muda o texto para "carregando..."
        e.preventDefault();
        setBotaoDesabilitado(true);
        setMensagemBotaoConfirmar(textoCarregando);

        // Faz o envio de informações para o back caso esteja tudo certo com os campos, se não tiver, retorna os campos inválidos
        const retornoRequest = await handleSubmit();
        const erroCustom = retornoRequest.success === undefined;

        if(retornoRequest === null) {
            NotifyUtils.info("Editando...");
            return;
        }

        if(!retornoRequest.success && !erroCustom) {
            NotifyUtils.erro(retornoRequest.message);
        } else if(retornoRequest.success) {
            NotifyUtils.sucesso(retornoRequest.message);
            NotifyUtils.sucesso("Redirecionando...");

            setCnpjValido(true);
            setRazaoSocialValida(true);
            setSiglaValida(true);
            setSituacaoCadastroValida(true);

            setTimeout(() => {
                handleCancelling();
                setBotaoDesabilitado(false);
                setMensagemBotaoConfirmar(textoConfirmar);
            }, 3500);
            return;
        } else if(erroCustom) {
            NotifyUtils.erro(retornoRequest.message);

            // Atualiza status dos campos inválisdos
            const camposInvalidos = retornoRequest.camposInvalidos;

            setCnpjValido(camposInvalidos.cnpjValido);
            setRazaoSocialValida(camposInvalidos.razaoSocialValida);
            setSiglaValida(camposInvalidos.siglaValida);
            setSituacaoCadastroValida(camposInvalidos.situacaoCadastroValida);
        }

        setBotaoDesabilitado(false);
        setMensagemBotaoConfirmar(textoConfirmar);
    }

    const clickBotaoCancelar = (e) => {
        e.preventDefault();
        handleCancelling();
    }

    let tiposDeDocumento = [];
    let situacoesCadastro = [];

    if (situacaoCadastro !== null) {
        situacoesCadastro = situacaoCadastro;
    } else {
        return <div>Loading...</div>;
    }

    if (empresaData === null || empresaData.idEmpresa === undefined || empresaData.idEmpresa === 0) {
        return <div>Loading...</div>;
    }

    if(tipoDocumento !== null) {
        tiposDeDocumento = tipoDocumento;
    } else {
        return <div>Loading...</div>;
    }

    return (
        <div className="container-editar-empresa">
            <div className="container-geral-cadastro-empresa">
                <HeaderContainers texto="edição de empresa" />
                <div className="formulario-editar-nova-empresa">
                    <form>
                        <div className="container-razao-sigla">
                            <InputPadrao
                                onChange={handleChange} 
                                obrigatorio={true} 
                                titulo="RAZÃO SOCIAL" 
                                icone={<MdAccountBalance />} 
                                placeholder={'Insira a razão social de sua empresa.'}
                                inputPara={"razaoSocial"}
                                name={"razao-social"}
                                comErro={!razaoSocialValida}
                                valorInicial={empresaData.razaoSocial}
                            />
                            <InputPadrao
                                onChange={handleChange} 
                                titulo="SIGLA" 
                                icone={<IoChatboxSharp />} 
                                placeholder={'Sigla da empresa.'}
                                inputPara={"sigla"}
                                name={"sigla"}
                                comErro={!siglaValida}
                                valorInicial={empresaData.sigla}
                            />
                        </div>

                        <div className="container-documentos-cadastro-empresa">
                            <div className="titulo-container-documentos">
                                <h3 className="titulo-documentos-cadastro-empresa">DOCUMENTOS</h3>
                                <hr className="divisor-top-container" />
                            </div>

                            <div className="container-inputs-selects">
                                <InputSelectPadrao
                                    onChange={handleChange}
                                    titulo="TIPO DE DOCUMENTO"
                                    icone={<MdContactPage />}
                                    obrigatorio={true}
                                    name={"tipo-documento"}
                                    placeholder={"Selecione o tipo"}
                                    options={tiposDeDocumento}
                                    inputPara={"tipoDocumento"}
                                    comErro={!cnpjValido}
                                    valorInicial={empresaData.tipoDocumento.descricao}
                                />

                                <InputPadrao
                                    onChange={handleChange}
                                    titulo="NÚMERO DO DOCUMENTO"
                                    icone={<MdDescription />}
                                    obrigatorio={true}
                                    placeholder={"Insira o documento"}
                                    inputPara={"numDocumento"}
                                    name={"numero-documento-cad-empresa"}
                                    mask={empresaData.tipoDocumento.descricao}
                                    comErro={!cnpjValido}
                                    valorInicial={empresaData.numDocumento}
                                />

                                <InputSelectPadrao
                                    onChange={handleChange}
                                    titulo="SITUAÇÃO DO CADASTRO"
                                    icone={<MdError />}
                                    obrigatorio={true}
                                    name={"situacao-cadastro"}
                                    placeholder={"Selecione a situação"}
                                    options={situacoesCadastro}
                                    inputPara={"situacaoCadastro"}
                                    comErro={!situacaoCadastroValida}
                                    valorInicial={empresaData.situacaoCadastro.descricao}
                                />
                            </div>
                        </div>

                        <div className="container-botoes-confirmar-cancelar-cadastro-empresa">

                            <BotaoPadrao 
                                acaoDeClicar={(e) => {clickBotaoCancelar(e)}}
                                texto="cancelar"
                                cancelar={true}
                                disabled={false}
                            />

                            <BotaoPadrao
                                confirmar={true}
                                acaoDeClicar={(e) => {clickBotaoConfirmar(e)}}
                                texto={mensagemBotaoConfirmar}
                                disabled={botaoDesabilitado}
                            />

                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

const transformaPrimeiraLetraEmMaiuscula = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export default FormEditarEmpresa;