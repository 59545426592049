// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-filtro-empresas {
    background-color: var(--branco-padrao);
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
    padding: .5rem .8rem 1rem .8rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-radius: 0 0 8px 8px;
}

.container-razao-sigla-endereco {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.input-razao-social-filtro-empresa {
    flex-grow: 2;
    margin-right: 1rem; 
}

.input-sigla-filtro-empresa {
    flex-grow: 1;
    margin-right: 1rem;
}

.input-endereco-filtro-empresa {
    flex-grow: 4;
}

.flex-blank {
    flex-grow: 2;
}

.container-low-filtro-empresas {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 100%;
}

.container-documentos-e-situacao-filtro-empresas {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    width: 100%;
}

.botoes-filtro-empresas {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: end;
}

.input-documento-filtro-empresa {
    flex-grow: 1.5;
}

.input-situacao-cadastro-filtro-empresa {
    flex-grow: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/FiltroEmpresaContainer/FiltroEmpresaContainer.css"],"names":[],"mappings":"AAAA;IACI,sCAAsC;IACtC,8CAA8C;IAC9C,+BAA+B;IAC/B,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,0BAA0B;AAC9B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,WAAW;AACf;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,WAAW;AACf;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".container-filtro-empresas {\n    background-color: var(--branco-padrao);\n    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);\n    padding: .5rem .8rem 1rem .8rem;\n    display: flex;\n    flex-direction: column;\n    gap: 1rem;\n    border-radius: 0 0 8px 8px;\n}\n\n.container-razao-sigla-endereco {\n    display: flex;\n    flex-direction: row;\n    width: 100%;\n}\n\n.input-razao-social-filtro-empresa {\n    flex-grow: 2;\n    margin-right: 1rem; \n}\n\n.input-sigla-filtro-empresa {\n    flex-grow: 1;\n    margin-right: 1rem;\n}\n\n.input-endereco-filtro-empresa {\n    flex-grow: 4;\n}\n\n.flex-blank {\n    flex-grow: 2;\n}\n\n.container-low-filtro-empresas {\n    display: flex;\n    flex-direction: row;\n    gap: 1rem;\n    width: 100%;\n}\n\n.container-documentos-e-situacao-filtro-empresas {\n    display: flex;\n    flex-direction: row;\n    gap: 1rem;\n    width: 100%;\n}\n\n.botoes-filtro-empresas {\n    display: flex;\n    flex-direction: row;\n    gap: 1rem;\n    align-items: end;\n}\n\n.input-documento-filtro-empresa {\n    flex-grow: 1.5;\n}\n\n.input-situacao-cadastro-filtro-empresa {\n    flex-grow: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
