import HeaderContainers from '../HeaderContainers/HeaderContainers';
import InputPadrao from '../InputPadrao/InputPadrao';
import BotaoPadrao from '../BotaoPadrao/BotaoPadrao';
import InputSelectPadrao from '../InputSelectPadrao/InputSelectPadrao';
import './FormEditarDepartamento.css'
import { MdBadge } from "react-icons/md";
import { useContext, useEffect, useState } from 'react';
import { RiErrorWarningFill } from "react-icons/ri";
import { getSituacaoCadastro } from '../../services/situacaoCadastroService';
import { SiGlassdoor } from "react-icons/si";
import { EditarDepartamentoContext } from '../../context/EditarDepartamentoContext';
import { getDepartamentoById } from '../../services/departamentoService';
import NotifyUtils from '../../utils/NotifyUtils';

function FormEditarDepartamento({ idDepartamento }) {

    const { departamentoData, setDepartamentoData, handleChange, handleCancelling, handleSubmit } = useContext(EditarDepartamentoContext);

    useEffect(() => {
        if(departamentoData === null || departamentoData === undefined || departamentoData.idDepartamento === 0) {
            getDepartamentoById(idDepartamento).then((departamento) => {
                setDepartamentoData(departamento);
            }).catch((error) => {
                console.error(error);
            });
        }
    }, []);

    const textoCarregando = "carregando...";
    const textoConfirmar = "confirmar";
    const [botaoDesabilitado, setBotaoDesabilitado] = useState(false);
    const [mensagemBotaoConfirmar, setMensagemBotaoConfirmar] = useState(textoConfirmar);

    const [ nomeDepartamentoValido, setNomeDepartamentoValido ] = useState(true);
    const [ siglaValida, setSiglaValida ] = useState(true);
    const [ situacaoCadastroValida, setSituacaoCadastroValida ] = useState(true);

    const [situacaoCadastro, setSituacaoCadastro] = useState(null);
    useEffect(() => {
        getSituacaoCadastro().then((situacaoCadastro) => {
            setSituacaoCadastro(situacaoCadastro);
        }).catch((error) => {
            console.error(error);
        });
    }, []);
    
    const handleCancelar = () => {
        handleCancelling();
    }

    if (departamentoData === null || departamentoData.idDepartamento === undefined || departamentoData.idDepartamento === 0) {
        return <div>Loading...</div>;
    }

    let situacoesCadastro = [];
    if (situacaoCadastro !== null) {
        situacoesCadastro = situacaoCadastro;
    } else {
        return <div>Loading...</div>;
    }

    const clickBotaoConfirmar = async (e) => {
        // Assim que confirmar, desabilita o botão e muda o texto para "carregando..."
        e.preventDefault();
        setBotaoDesabilitado(true);
        setMensagemBotaoConfirmar(textoCarregando);

        // Faz o envio de informações para o back caso esteja tudo certo com os campos, se não tiver, retorna os campos inválidos
        const retornoRequest = await handleSubmit();
        const erroCustom = retornoRequest.success === undefined;

        if(retornoRequest === null) {
            NotifyUtils.info("Editando...");
            return;
        }

        if(!retornoRequest.success && !erroCustom) {
            NotifyUtils.erro(retornoRequest.message);
        } else if(retornoRequest.success) {
            NotifyUtils.sucesso(retornoRequest.message);
            NotifyUtils.sucesso("Redirecionando...");

            setNomeDepartamentoValido(true);
            setSiglaValida(true);
            setSituacaoCadastroValida(true);

            setTimeout(() => {
                handleCancelling();
                setBotaoDesabilitado(false);
                setMensagemBotaoConfirmar(textoConfirmar);
            }, 3500);
            return;
        } else if(erroCustom) {
            NotifyUtils.erro(retornoRequest.message);

            // Atualiza status dos campos inválisdos
            const camposInvalidos = retornoRequest.camposInvalidos;

            setNomeDepartamentoValido(camposInvalidos.nomeValido);
            setSiglaValida(camposInvalidos.siglaValida);
            setSituacaoCadastroValida(camposInvalidos.situacaoValida);
        }

        setBotaoDesabilitado(false);
        setMensagemBotaoConfirmar(textoConfirmar);
    }

    return (
        <div className="container-cadastrar-departamento">
            <div className="container-geral-cadastro-departamento">
                <HeaderContainers texto="edição de departamento" />

                <div className="formulario-cadastrar-departamento">
                    <form>
                        <div className="inputs-principais-cadastro-departamento">
                            <InputPadrao
                                onChange={handleChange}
                                titulo={"Nome do Departamento"}
                                obrigatorio={true}
                                name={"nome-departamento"}
                                className={"nome-departamento-cad"}
                                placeholder={"Insira o nome do departamento (ex: T.I., Suporte, Desenvolvimento)"}
                                inputPara={"nomeDepartamento"}
                                icone={<MdBadge />}
                                comErro={!nomeDepartamentoValido}
                                valorInicial={departamentoData.nomeDepartamento}
                            />

                            <InputPadrao
                                onChange={handleChange}
                                className={"sigla-departamento-cad"}
                                titulo={"SIGLA"}
                                obrigatorio={true}
                                name={"sigla-departamento"}
                                placeholder={"Insira a sigla do departamento (ex: TI, SUP, DEV)"}
                                inputPara={"sigla"}
                                icone={<SiGlassdoor />}
                                comErro={!siglaValida}
                                valorInicial={departamentoData.sigla}
                            />

                            <InputSelectPadrao
                                onChange={handleChange}
                                className={"situacao-cadastro-departamento-cad"}
                                titulo={"Situação do Cadastro"}
                                obrigatorio={true}
                                icone={<RiErrorWarningFill />}
                                name={"situacao-cadastro"}
                                placeholder={"Selecione a situação"}
                                inputPara={"situacaoCadastro"}
                                options={situacoesCadastro}
                                comErro={!situacaoCadastroValida}
                                valorInicial={departamentoData.situacaoCadastro.descricao}
                            />
                        </div>

                        <div className="botoes-cadastro-departamento">
                            <BotaoPadrao
                                acaoDeClicar={handleCancelar}
                                texto={"Cancelar"}
                                cancelar={true}
                            />
                            <BotaoPadrao
                                acaoDeClicar={clickBotaoConfirmar}
                                texto={mensagemBotaoConfirmar}
                                confirmar={true}
                                disabled={botaoDesabilitado}
                            />
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default FormEditarDepartamento;