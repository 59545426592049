import SituacaoCadastro from '../SituacaoCadastro/SituacaoCadastro';
import './ListViewDepartamento.css';
import { IoIosWarning } from "react-icons/io";
import { HiPencil } from "react-icons/hi";
import PaginacaoPadrao from '../PaginacaoPadrao/PaginacaoPadrao';

function ListViewDepartamento({
    departamentos,
    page,
    infoPagina,
    setPage
}) {

    const handleEditarDepartamento = (idDepartamento) => {
        // Função que direciona para a página de edição de departamento
        window.location.href = `/editar-departamento/${idDepartamento}`;
    }

    return (
        <div className="container-geral-list-view-departamento">
            <div className="quantitativo-resultados-encontrados-list-view-departamentos">
                <span className="quantidade-departamentos-encontradas">{infoPagina.totalElements}</span>
                <p className="texto-resultado-departamentos-encontradas">Resultados Encontrados</p>
            </div>

            {infoPagina.totalElements === 0 
                ? (
                    <div className="container-list-view-departamento-nao-encontrado">
                        <NenhumEncontrada />
                    </div>
                ) : (
                    <div className="container-list-view-departamento">
                        <HeaderListViewDepartamento />
                        {departamentos.map((departamento, index) => {
                            return (
                                <Departamento onEdit={handleEditarDepartamento} key={index} departamento={departamento} />
                            )
                        })}

                        <PaginacaoPadrao
                            page={page}
                            setCurrentPage={setPage}
                            lastPage={infoPagina.totalPages}
                        />
                    </div>
                )}
        </div>
    )
}

const NenhumEncontrada = () => {
    return (
        <div className="nenhum-departamento-encontrada-container">
            <IoIosWarning 
                className="icone-departamento-na-encontrada"
            />
            <h2 className="h2-nenhum-resultado-encontrado">NENHUM RESULTADO ENCONTRADO</h2>
            <p className="referencia-cadastro-nova-departamento">Tente colocar outros filtros, ou <a href="/cadastrar-departamento">cadastre um novo departamento</a>.</p>
        </div>
    )
}

const HeaderListViewDepartamento = () => {
    return (
        <div className="header-list-view-departamento">
            <span className="header-acao-departamento">AÇÃO</span>
            <span className="header-nome-departamento">NOME DO DEPARTAMENTO</span>
            <span className="header-sigla-departamento">SIGLA</span>
            <span className="header-situacao-departamento">SITUAÇÃO</span>
            <span className="header-usuarios-vinculados-departamento">USUÁRIOS VINCULADOS</span>
        </div>
    )
}

const Departamento = ({departamento, onEdit}) => {

    return (
        <div className="departamento-list-view-departamento">
            <span className="acao-departamento">
                <IconeEditar
                    onClick={onEdit.bind(this, departamento.id)}
                />
            </span>
            <span className="nome-departamento">{departamento.nome}</span>
            <span className="sigla-departamento">{departamento.sigla}</span>
            <span className="situacao-departamento">
                <SituacaoCadastro
                    situacao={departamento.situacao}
                />
            </span>
            <span className="usuarios-vinculados-departamento">{departamento.numUsuarios}</span>
        </div>
    )
}

const IconeEditar = ({
    onClick
}) => {
    return (
        <HiPencil
            className="icone-editar-departamento"
            onClick={onClick}
        />
    )
}

export default ListViewDepartamento;