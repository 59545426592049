import api from "./api";

export const getPerfilUsuario = async () => {
    try {
        const response = await api.get("/perfilUsuario_");

        if(response.data.length === 3) {
            response.data.shift();
        }
        
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
}