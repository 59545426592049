import { createContext, useState } from 'react';
import { updateDepartamento } from '../services/departamentoService';
import { useNavigate } from 'react-router-dom';
import { validaCampoVazio, validaSigla } from '../validations/basicsValidations';
import { montaResposta } from '../utils/ResponsesUtils';

export const EditarDepartamentoContext = createContext();

export const EditarDepartamentoProvider = ({ children }) => {
    const navigate = useNavigate();

    const initState = {
        idDepartamento: 0,
        nomeDepartamento: '',
        sigla: '',
        descricao: '',
        situacaoCadastro: '',
    }

    const [ mudou, setMudou ] = useState(false);
    const [ lastDepartamentoData, setLastDepartamentoData ] = useState(null);

    const [departamentoData, setDepartamentoData] = useState(initState);

    // Função para atualizar o estado do departamento que está sendo cadastrada (um por vez)
    const handleChange = (field, value) => {

        if(!mudou) {
            setLastDepartamentoData(departamentoData);
        }

        setMudou(true);

        setDepartamentoData(estadoAnterior => ({
            ...estadoAnterior,
            [field]: value,
        }));
    };

    const handleSubmit = async () => {
        // Verifica se houve mudança de informações
        const mudouInformacoes = verificaSeMudouInformacoes(departamentoData, lastDepartamentoData);

        if(!mudouInformacoes) {
            return montaResposta(
                true,
                'Nenhuma informação foi alterada.',
                null,
                null
            );
        }

        // Faz todas as validações para verificar se os campos estão corretamente preenchidos
        const nomeValido = validaCampoVazio(departamentoData.nomeDepartamento);
        const situacaoValida = validaCampoVazio(departamentoData.situacaoCadastro);
        const siglaValida = validaSigla(departamentoData.sigla);

        if(nomeValido 
            && situacaoValida 
            && siglaValida) {

            try {
                departamentoData.sigla = departamentoData.sigla.toUpperCase();
            } catch (error) {
                console.error(error);
                return null;
            }

            const deptoSalvo = await updateDepartamento(departamentoData);
            
            if(deptoSalvo) {
                return montaResposta(
                    true, 
                    'Departamento editado com sucesso.',
                    null,
                    null);
            } else {
                return montaResposta(
                    false, 
                    'Parabéns! Você desbloqueou o easter egg!',
                    null,
                    null);
            }

        } else {
            // Retorna todos os campos que estão inválidos
            return montaResposta(
                undefined, 
                'Preencha corretamente os campos obrigatórios.',
                null,
                { nomeValido, situacaoValida, siglaValida });
        }
    };

    const handleCancelling = () => {
        handleClear();
        irParaListViewDepartamento();
    };

    const handleClear = () => {
        setDepartamentoData(() => (initState));
        setMudou(false);
        setLastDepartamentoData(null);
    };

    const irParaListViewDepartamento = () => {
        navigate('/departamentos');
    };

    return (
        <EditarDepartamentoContext.Provider 
            value={{ departamentoData, setDepartamentoData, handleChange, handleCancelling, handleSubmit }}>
            {children}
        </EditarDepartamentoContext.Provider>
    )
}

const verificaSeMudouInformacoes = (departamentoData, lastDepartamentoData) => {
    if(lastDepartamentoData === null) {
        return false;
    }

    if(departamentoData.nomeDepartamento !== lastDepartamentoData.nomeDepartamento) {
        return true;
    }

    if(departamentoData.sigla !== lastDepartamentoData.sigla) {
        return true;
    }

    if(departamentoData.descricao !== lastDepartamentoData.descricao) {
        return true;
    }

    if(departamentoData.situacaoCadastro !== lastDepartamentoData.situacaoCadastro) {
        return true;
    }

    return false;
};