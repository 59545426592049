// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-main-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    pointer-events: auto;
    gap: .5rem;
    z-index: 999999;

    background-color: rgba(0, 0, 0, .5);
    -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
}

.texto-carregando {
    font-size: 2rem;
    color: var(--azul-dixi);
}

.loading-main-container .icone-loading {
    font-size: 2.6rem;
    color: var(--azul-dixi);
    animation: girar 1s linear infinite;
}

.texto-carregando, .icone-loading {
    pointer-events: none;
    opacity: .9;
}

@keyframes girar {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/Loading/Loading.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;;IAEZ,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;;IAEnB,oBAAoB;IACpB,UAAU;IACV,eAAe;;IAEf,mCAAmC;IACnC,kCAA0B;YAA1B,0BAA0B;AAC9B;;AAEA;IACI,eAAe;IACf,uBAAuB;AAC3B;;AAEA;IACI,iBAAiB;IACjB,uBAAuB;IACvB,mCAAmC;AACvC;;AAEA;IACI,oBAAoB;IACpB,WAAW;AACf;;AAEA;IACI;QACI,uBAAuB;IAC3B;IACA;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":[".loading-main-container {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n\n    pointer-events: auto;\n    gap: .5rem;\n    z-index: 999999;\n\n    background-color: rgba(0, 0, 0, .5);\n    backdrop-filter: blur(4px);\n}\n\n.texto-carregando {\n    font-size: 2rem;\n    color: var(--azul-dixi);\n}\n\n.loading-main-container .icone-loading {\n    font-size: 2.6rem;\n    color: var(--azul-dixi);\n    animation: girar 1s linear infinite;\n}\n\n.texto-carregando, .icone-loading {\n    pointer-events: none;\n    opacity: .9;\n}\n\n@keyframes girar {\n    0% {\n        transform: rotate(0deg);\n    }\n    100% {\n        transform: rotate(360deg);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
