import API_BASE_URL from "../config/apiConfig";

const SEPARADOR_FILTRO = "&";

export const localDateTimeArrToStringFormatado = (dataArr) => {
    let dataString = '';
    
    let ano = dataArr[0];
    let mes = dataArr[1];
    if(mes < 10) mes = `0${mes}`;
    let dia = dataArr[2];
    if(dia < 10) dia = `0${dia}`;

    let hora = dataArr[3];
    if(hora < 10) hora = `0${hora}`;
    let minuto = dataArr[4];
    if(minuto < 10) minuto = `0${minuto}`;

    dataString = `${dia}/${mes}/${ano} às ${hora}:${minuto}`;

    return dataString;
}

export const montaUrlFiltroPaginacaoChamado = (page, pageSize, filter, filterType) => {
    let filtro = "";

    if(filter !== '') {
        filtro += SEPARADOR_FILTRO + `${filterType}=${filter}`;
    }

    return `?page=${page}&size=${pageSize}${filtro}`;
};

export const montaUrlFiltroPaginacaoEmpresa = (endpoint, empresaFilter, page, pageSize) => {
    let filtro = "";

    if(empresaFilter.razaoSocial !== '') {
        filtro += SEPARADOR_FILTRO + `razaoSocial=${empresaFilter.razaoSocial}`;
    }

    if(empresaFilter.sigla !== '') {
        filtro += SEPARADOR_FILTRO + `sigla=${empresaFilter.sigla}`;
    }

    if(empresaFilter.numDocumento !== '') {
        let documentoFiltro = mantemNumeros(empresaFilter.numDocumento);
        filtro += SEPARADOR_FILTRO + `documento=${documentoFiltro}`;
    }

    if(empresaFilter.situacaoCadastro !== '') {
        filtro += SEPARADOR_FILTRO + `situacaoCadastro=${empresaFilter.situacaoCadastro}`;
    }

    if(empresaFilter.tipoDocumento !== '') {
        filtro += SEPARADOR_FILTRO + `tipoDocumento=${empresaFilter.tipoDocumento}`;
    }

    return endpoint + `?page=${page}&size=${pageSize}${filtro}`;
};

export const montaUrlFiltroPaginacaoUsuario = (endpoint, usuarioFilter, page, pageSize) => {
    let filtro = "";

    if(usuarioFilter.nome !== '') {
        filtro += SEPARADOR_FILTRO + `nome=${usuarioFilter.nome}`;
    }

    if(usuarioFilter.email !== '') {
        filtro += SEPARADOR_FILTRO + `email=${usuarioFilter.email}`;
    }

    if(usuarioFilter.cpf !== '') {
        filtro += SEPARADOR_FILTRO + `cpf=${usuarioFilter.cpf}`;
    }

    if(usuarioFilter.situacaoCadastro !== '') {
        filtro += SEPARADOR_FILTRO + `situacaoCadastro=${usuarioFilter.situacaoCadastro}`;
    }

    return endpoint + `?page=${page}&size=${pageSize}${filtro}`;
}

export const montaUrlFiltroPaginacaoDepartamento = (endpoint, departamentoFilter, page, pageSize) => {
    let filtro = "";

    if(departamentoFilter.nomeDepartamento !== '') {
        filtro += SEPARADOR_FILTRO + `nome=${departamentoFilter.nomeDepartamento}`;
    }

    if(departamentoFilter.situacaoCadastro !== '') {
        filtro += SEPARADOR_FILTRO + `situacao=${departamentoFilter.situacaoCadastro}`;
    }

    if(departamentoFilter.sigla !== '') {
        filtro += SEPARADOR_FILTRO + `sigla=${departamentoFilter.sigla}`;
    }

    return endpoint + `?page=${page}&size=${pageSize}${filtro}`;
}

export const montaCaminho = (caminho) => {
    const DIVISOR = "/";
    const CAMINHO_EXTRA_IMAGEM = "/imagem_"

    return API_BASE_URL + 
        CAMINHO_EXTRA_IMAGEM + 
        DIVISOR +
        caminho;
}

export const mantemNumeros = (valor) => {
    return valor.replace(/\D/g, '');
}