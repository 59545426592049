import api from "./api";

const endpoint = '/imagemComentario_';

export const saveAllImagemComentario = async (imagensComentario) => {
    try {
        // Enviando as imagemComentario
        const response = await api.post(endpoint + "/all", imagensComentario);
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export const getImagensByComentario = async (comentario) => {
    try {
        const response = await api.post(endpoint + "/byComentario", comentario);
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
}