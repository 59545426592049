import './SituacaoCadastro.css';

function SituacaoCadastro({
    situacao
}) {

  const idSituacao = situacao === "Ativo" ? 1 : 2;

  return (
    <p className={`ativo-inativo-texto sit-${idSituacao}`}>{situacao}</p>
  )
}

export default SituacaoCadastro;