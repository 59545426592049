// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-filtro-usuarios {
    background-color: var(--branco-padrao);
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
    padding: .5rem .8rem 1rem .8rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-radius: 0 0 8px 8px;
}

.inputs-principal-filtro-usuario {
    display: flex;
    width: 100%;
    gap: .7rem;
}

.input-nome-filtro-usuario {
    flex-grow: 2;
}

.input-email-filtro-usuario {
    flex-grow: 2;
}

.input-cpf-filtro-usuario {
    flex-grow: 1;
}

.input-situacao-cadastro-filtro-usuario {
    flex-grow: 1;
}

.botoes-filtro-usuarios {
    display: flex;
    gap: 1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/FiltroUsuarioContainer/FiltroUsuarioContainer.css"],"names":[],"mappings":"AAAA;IACI,sCAAsC;IACtC,8CAA8C;IAC9C,+BAA+B;IAC/B,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,0BAA0B;AAC9B;;AAEA;IACI,aAAa;IACb,WAAW;IACX,UAAU;AACd;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,SAAS;AACb","sourcesContent":[".container-filtro-usuarios {\n    background-color: var(--branco-padrao);\n    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);\n    padding: .5rem .8rem 1rem .8rem;\n    display: flex;\n    flex-direction: column;\n    gap: 1rem;\n    border-radius: 0 0 8px 8px;\n}\n\n.inputs-principal-filtro-usuario {\n    display: flex;\n    width: 100%;\n    gap: .7rem;\n}\n\n.input-nome-filtro-usuario {\n    flex-grow: 2;\n}\n\n.input-email-filtro-usuario {\n    flex-grow: 2;\n}\n\n.input-cpf-filtro-usuario {\n    flex-grow: 1;\n}\n\n.input-situacao-cadastro-filtro-usuario {\n    flex-grow: 1;\n}\n\n.botoes-filtro-usuarios {\n    display: flex;\n    gap: 1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
