import { createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { validaCampoVazio, validaDocumento } from '../validations/basicsValidations';
import { montaResposta } from '../utils/ResponsesUtils';
import { updateEmpresa } from '../services/empresaService';

export const EditarEmpresaContext = createContext();

export const EditarEmpresaProvider = ({ children }) => {
    const navigate = useNavigate();

    const [ mudou, setMudou ] = useState(false);
    const [ lastEmpresaData, setLastEmpresaData ] = useState(null);

    const [empresaData, setEmpresaData] = useState({
        idEmpresa: 0,
        razaoSocial: '',
        sigla: '',
        tipoDocumento: '',
        numDocumento: '',
        situacaoCadastro: '',
    });

    // Função para atualizar o estado da empresa que está sendo cadastrada (um por vez)
    const handleChange = (field, value) => {

        if(!mudou) {
            setLastEmpresaData(empresaData);
        }

        setMudou(true);

        setEmpresaData(estadoAnterior => ({
            ...estadoAnterior,
            [field]: value,
        }));
    };

    const handleSubmit = async () => {
        // Verifica se houve mudança de informações
        const mudouInformacoes = verificaSeMudouInformacoes(empresaData, lastEmpresaData);

        if(!mudouInformacoes) {
            return montaResposta(
                true,
                'Nenhuma informação foi alterada.',
                null,
                null
            );
        }

        // VALIDAÇÕES DE EMPRESA
        const cnpjValido = validaDocumento(empresaData.tipoDocumento, empresaData.numDocumento);
        const razaoSocialValida = validaCampoVazio(empresaData.razaoSocial);
        const siglaValida = validaCampoVazio(empresaData.sigla);
        const situacaoCadastroValida = validaCampoVazio(empresaData.situacaoCadastro);

        if(cnpjValido 
            && razaoSocialValida 
            && siglaValida 
            && situacaoCadastroValida) {
            
            try {
                empresaData.sigla = empresaData.sigla.toUpperCase();
                empresaData.numDocumento = empresaData.numDocumento.replace(/[^\d]+/g,'');
            } catch (error) {
                console.error(error);
                return null;
            }

            // Requisição para edição de empresa
            return await updateEmpresa(empresaData);
        } else {
            // Retorna todos os campos que estão inválidos
            return montaResposta(
                undefined, 
                'Preencha corretamente os campos obrigatórios.',
                null,
                { cnpjValido, razaoSocialValida, siglaValida,situacaoCadastroValida });
        }
    };

    const handleCancelling = () => {
        handleClear();
        irParaListViewEmpresa();
    };

    const irParaListViewEmpresa = () => {
        navigate('/empresas');
        window.location.reload();
    };

    const handleClear = () => {
        setEmpresaData(() => ({
            idEmpresa: 0,
            razaoSocial: '',
            sigla: '',
            tipoDocumento: '',
            numDocumento: '',
            situacaoCadastro: '',
        }));
        setMudou(false);
        setLastEmpresaData(null);
    };

    return (
        <EditarEmpresaContext.Provider 
            value={{ empresaData, setEmpresaData, handleCancelling, handleChange, handleSubmit }}>
            {children}
        </EditarEmpresaContext.Provider>
    )
}

const verificaSeMudouInformacoes = (empresaData, lastEmpresaData) => {
    if(lastEmpresaData === null) {
        return false;
    }

    if(empresaData.razaoSocial !== lastEmpresaData.razaoSocial) {
        return true;
    }

    if(empresaData.sigla !== lastEmpresaData.sigla) {
        return true;
    }

    if(empresaData.tipoDocumento !== lastEmpresaData.tipoDocumento) {
        return true;
    }

    if(empresaData.numDocumento !== lastEmpresaData.numDocumento) {
        return true;
    }

    if(empresaData.situacaoCadastro !== lastEmpresaData.situacaoCadastro) {
        return true;
    }

    return false;
};
