import React from 'react'
import Header from '../components/Header/Header'
import MenuLateral from '../components/MenuLateral/MenuLateral'
import FormCadastroEmpresa from '../components/FormCadastroEmpresa/FormCadastroEmpresa'
import { EmpresaProvider } from '../context/EmpresaContext'

function CadastroEmpresa() {
  return (
    <EmpresaProvider>
      <div className="main-container">
        <Header />
        <MenuLateral telaAtual={"empresas"} />
        <FormCadastroEmpresa />
      </div>
    </EmpresaProvider>
  )
}

export default CadastroEmpresa