import './FormEditarChamado.css'
import { FaUser, FaCalendar, FaDownload  } from "react-icons/fa";
import { EditTicketContext } from '../../context/EditTicketContext';
import { useContext } from 'react';
import HeaderContainers from '../HeaderContainers/HeaderContainers';
import { localDateTimeArrToStringFormatado, montaCaminho } from '../../utils/DataUtils';
import Anexo from '../Anexo/Anexo';

function FormEditarChamado() {

    const { ticketData } = useContext(EditTicketContext);

    if(ticketData === undefined) {
        return <div>Loading...</div>;
    }

    const temImagens = ticketData.imagens.length > 0;
    const temAnexos = ticketData.anexos.length > 0;
    const textoNumeroChamado = "#" + ticketData.idChamado;
    const semTituloText = "SEM TÍTULO"
    const semTitulo = ticketData.assunto === undefined || ticketData.assunto === "";

    return (
        <div className="visualizar-chamado-container-maior">
            <HeaderContainers extra={textoNumeroChamado} cinza={true} texto={semTitulo ? semTituloText : ticketData.assunto} />

            <div className="container-visualizar-chamado">
                <form>
                    {/* Cabeçalho do chamado */}
                    <div className="informacoes-adicionais-chamado">
                        <div className="subs-do-editar-chamado">
                            <p className="texto-info-adicional">
                                <FaUser className="icone-usuario" />{ticketData.usuarioResponsavel.nome}
                            </p>
                            <p className="texto-info-adicional">
                                <FaCalendar className="icone-data" />{ticketData.dataCriacao !== undefined ? localDateTimeArrToStringFormatado(ticketData.dataCriacao): ''}
                            </p>
                        </div>
                        
                        <hr className="linha-divisora-info-adicional-vis-chamado" />
                    </div>

                    <div className="container-input-chamado">
                        <p className="descricao-do-chamado">{ticketData.descricao}</p>

                        {temImagens 
                            && <ImagensSelecionadasVisChamado
                                    imagens={[ticketData.imagens]}
                                    onRemove={null}
                                />}
                        
                        {temAnexos 
                            && <AnexosSelecionados
                                anexos={ticketData.anexos}
                            />}

                    </div>
                </form>
            </div>
        </div>
    )
}

const ImagensSelecionadasVisChamado = ({
    imagens,
}) => {

    const direcionaParaImagem = (url) => {
        window.open(url, "_blank");
    }

    return (
        <div className="imagens-adicionadas-pelo-usuario-vis-chamado">
            {imagens.flat().map((imagem, index) => (
                <div key={index} className="container-imagem-selecionada-vis-chamado">
                    <img className="imagem-selecionada-pelo-usuario-chamado-vis-chamado" onClick={() => {direcionaParaImagem(montaCaminho(imagem.enderecoImagem))}} src={montaCaminho(imagem.enderecoImagem)} alt="Arquivo Selecionado" />
                </div>
            ))}
        </div>
    )
}

const AnexosSelecionados = ({ anexos }) => {

    const { handleAnexoDownload } = useContext(EditTicketContext);

    return (
        <div className='container-global-anexos-vis'>
            <p className='escrita-anexos-vis'>ANEXOS</p>
            <hr className='linha-anexos-vis'></hr>
            <div className='container-anexos-vis'>
                {anexos.map((anexo, index) => {

                    let tipoArquivo = anexo.enderecoImagem.split('.').pop();

                    return (
                        <div key={index} className="container-anexo-selecionado-vis">
                            <div className='container-interno-anexo-vis'>
                                <Anexo
                                    tipoDeArquivo={tipoArquivo}
                                />
                                <div className='informacoes-internas-anexo-vis'>
                                    <p className="nome-anexo-selecionado-pelo-usuario-chamado-vis" title={anexo.tituloImagem}>
                                        {anexo.tituloImagem}
                                    </p>
                                </div>
                                <FaDownload  className='anexo-download-icon' onClick={() => {handleAnexoDownload(anexo.enderecoImagem)}}/>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default FormEditarChamado