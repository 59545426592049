const validaNome = (nome) => {

    let ehValido = false;
    let padraoNomeValido = /[^a-zà-ú]/gi;

    const possuiCaracteres = padraoNomeValido.test(nome);
    const maiorQue = nome.length >= 3;
    const menorQue = nome.length <= 25;

    if (maiorQue && menorQue && !possuiCaracteres) {
        ehValido = true;
    }

    return ehValido;
}

const validaEmail = (email) => {
    // Regex para validação de e-mail
    const modeloCorretoEmail = /^[a-z0-9.]+@[a-z0-9]+\.[a-z]+(\.[a-z]+)?$/i;
    return modeloCorretoEmail.test(email);
}

const validaSenha = (senha) => {

    let ehValido = false;
    const maiorQue = senha.length >= 3;

    if(maiorQue) {
        ehValido = true;
    }

    return ehValido;
}

const validaConfirmaSenha = (senha, confirmaSenha) => {

    let ehValido = false;
    const maiorQue = confirmaSenha.length > 2;

    if(senha === confirmaSenha && maiorQue) {
        ehValido = true;
    }

    return ehValido;
}

export { validaNome, validaEmail, validaSenha, validaConfirmaSenha };