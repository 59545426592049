import SituacaoCadastro from '../SituacaoCadastro/SituacaoCadastro';
import './ListViewEmpresa.css';
import { IoIosWarning } from "react-icons/io";
import { HiPencil } from "react-icons/hi";
import PaginacaoPadrao from '../PaginacaoPadrao/PaginacaoPadrao';

function ListViewEmpresa({ 
    empresas,
    page,
    infoPagina,
    setPage 
}) {

    const handleEditarEmpresa = (idEmpresa) => {
        // Função que direciona para a página de edição da empresa
        window.location.href = `/editar-empresa/${idEmpresa}`;
    }

    return (
        <div className="container-geral-list-view-empresa">
            <div className="quantitativo-resultados-encontrados-list-view-empresas">
                <span className="quantidade-empresas-encontradas">{infoPagina.totalElements}</span>
                <p className="texto-resultado-empresas-encontradas">Resultados Encontrados</p>
            </div>

            {infoPagina.totalElements === 0 
                ? 
                (
                    <div className="container-list-view-empresa-nao-encontrada">
                        <NenhumaEncontrada />
                    </div>
                ) 
                : 
                (
                    <div className="container-list-view-empresa">
                        <HeaderListViewEmpresa />
                        {empresas.map((empresa, index) => {
                            return (
                                <Empresa onEdit={handleEditarEmpresa} key={index} empresa={empresa} />
                            )
                        })}

                        <PaginacaoPadrao
                            page={page}
                            setCurrentPage={setPage}
                            lastPage={infoPagina.totalPages}
                        />
                    </div>
                )}
        </div>
    )
}

const NenhumaEncontrada = () => {
    return (
        <div className="nenhuma-empresa-encontrada-container">
            <IoIosWarning 
                className="icone-empresa-na-encontrada"
            />
            <h2 className="h2-nenhum-resultado-encontrado">NENHUM RESULTADO ENCONTRADO</h2>
            <p className="referencia-cadastro-nova-empresa">Tente colocar outros filtros, ou <a href="/cadastrar-empresa">cadastre uma nova empresa</a>.</p>
        </div>
    )
}

const HeaderListViewEmpresa = () => {
    return (
        <div className="header-list-view-empresa">
            <span className="header-acao">AÇÃO</span>
            <span className="header-razao-social">RAZÃO SOCIAL</span>
            <span className="header-sigla">SIGLA</span>
            <span className="header-situacao">SITUAÇÃO</span>
            <span className="header-cpf-cnpj">DOCUMENTO</span>
            <span className="header-departamentos">DEPARTAMENTOS</span>
        </div>
    )
}

const Empresa = ({empresa, onEdit}) => {

    const formataCNPJ = (cnpj) => {
        return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    }

    const formataCPF = (cpf) => {
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    }
    
    return (
        <div className="empresa-list-view-empresa">
            <span className="acao-empresa">
                <IconeEditar
                    onClick={onEdit.bind(this, empresa.id)}
                />
            </span>
            <span className="razao-social-empresa">{empresa.razaoSocial}</span>
            <span className="sigla-empresa">{empresa.sigla}</span>
            <span className="situacao-empresa">
                <SituacaoCadastro
                    situacao={empresa.situacaoCadastro}
                />
            </span>
            <span className="cpf-cnpj-empresa">
                {empresa.tipoDocumento === 'CPF' 
                    ? formataCPF(empresa.documento)
                    : formataCNPJ(empresa.documento)}
            </span>
            <span className="departamentos-empresa">{empresa.numDepartamentos}</span>
        </div>
    )
}

const IconeEditar = ({
    onClick
}) => {
    return (
        <HiPencil
            className="icone-editar-empresa"
            onClick={onClick}
        />
    )
}

export default ListViewEmpresa;