import './PainelExpansivelChamado.css';
import { useContext, useEffect, useState } from 'react';
import { MdArrowDropDown } from "react-icons/md";
import { IoMdArrowDropup } from "react-icons/io";
import ChamadoList from '../ChamadoList/ChamadoList';
import { ExpansionPanel } from 'react-md';
import { useAuth } from '../../services/AuthContext';
import { getChamados, getChamadosFiltrados } from '../../services/ticketService';
import PaginacaoPadrao from '../PaginacaoPadrao/PaginacaoPadrao';
import { TicketFilterContext } from '../../context/TicketFilterContext';
import { retornaFiltro } from '../../utils/ChamadoUtils';

function PainelExpansivelChamado({ status }) {
    const { user } = useAuth();
    const { ticketFilter, filtrar, tipoFiltro, temFiltro } = useContext(TicketFilterContext);

    const [ page , setPage ] = useState(0);

    const [ chamados, setChamados ] = useState([]);
    const [ infoPagina, setInfoPagina ] = useState({});
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        const fetchChamados = async () => {
            let response = null;
            setLoading(true);

            if(temFiltro()) {
                response = await getChamadosFiltrados(user, status.idStatus, page, retornaFiltro(ticketFilter), tipoFiltro);
                
                if(response.content.length > 0 && temFiltro()) {
                    setExpanded(true);
                } else {
                    setExpanded(false);
                }
            } else {
                response = await getChamados(user, status.idStatus, page);
            }

            setChamados(response.content);
            setInfoPagina(response);
            setLoading(false);
        };

        fetchChamados();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, filtrar]);
    
    const tituloExpansivel = status.descricao;
    const tipoListaChamado = status.idStatus;

    const [expanded, setExpanded] = useState(false);

    const handleToggle = () => {
        setExpanded(!expanded);
    }
    
    return (
        infoPagina.totalElements > 0 ? (
        <ExpansionPanel
            expanded={expanded}
            onExpandClick={handleToggle}
            header={`${tituloExpansivel} (${infoPagina.totalElements})`}
            className={`expansion-panel-header`}
            headerClassName={`expansion-panel-header tipo${tipoListaChamado} ${expanded ? "" : "naoEstaExpandido"}`}
            contentClassName={`expansion-panel-content ${expanded ? 'expanded' : ""}`}
            expanderIcon={expanded ? <IoMdArrowDropup className="icone-expansion-paineis"/> : <MdArrowDropDown className={`icone-expansion-paineis ${expanded}`}/>}>
            <ul>
                {chamados.map((chamado, index) => (
                    <ChamadoList
                        key={index}
                        chamado={chamado}
                        idChamado={chamado.id}
                        tipoListaChamado={tipoListaChamado}
                        filhoUnico={infoPagina.totalElements === 1}
                    />
                ))}
            </ul>

            <div className="info-paginacao-container">
                <PaginacaoPadrao
                    page={page}
                    setCurrentPage={setPage}
                    lastPage={infoPagina.totalPages}
                    loading={loading}
                />
            </div>
        </ExpansionPanel>) : (
                <ExpansionPanel
                    expanded={expanded}
                    header={`${tituloExpansivel} - ${loading ? "Carregando" : "Nenhum chamado encontrado"}`}
                    className={`expansion-panel-header`}
                    headerClassName={`expansion-panel-header sem-chamados tipo${tipoListaChamado} ${expanded ? "" : "naoEstaExpandido"}`}
                    contentClassName={`expansion-panel-content ${expanded ? 'expanded' : ""}`}
                    expanderIcon={<MdArrowDropDown className={`icone-expansion-paineis ${"sem-chamados"}`}/>}>
                </ExpansionPanel>
        )
    )
}

export default PainelExpansivelChamado;

