import React from 'react'
import Header from '../components/Header/Header'
import MenuLateral from '../components/MenuLateral/MenuLateral'
import FormEditarUsuario from '../components/FormEditarUsuario/FormEditarUsuario'
import { useParams } from 'react-router-dom'
import { EditarUsuarioProvider } from '../context/EditarUsuarioContext'

function EditarUsuario() {

  const { idUsuario } = useParams();

  return (
    <EditarUsuarioProvider>
      <div className="main-container">
        <Header />
        <MenuLateral telaAtual={"usuarios"} />
        <FormEditarUsuario idUsuario={idUsuario} />
      </div>
    </EditarUsuarioProvider>
  )
}

export default EditarUsuario