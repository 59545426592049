import { IoIosWarning } from 'react-icons/io';
import './ListViewUsuario.css';
import { HiPencil } from 'react-icons/hi';
import SituacaoCadastro from '../SituacaoCadastro/SituacaoCadastro';
import { getUsuarios, getUsuariosFiltrados } from '../../services/usuarioService';
import { useContext, useEffect, useState } from 'react';
import { UsuarioFilterContext } from '../../context/UsuarioFilterContext';
import Loading from '../Loading/Loading';
import PaginacaoPadrao from '../PaginacaoPadrao/PaginacaoPadrao';

function ListViewUsuario() {

    const [ loading, setLoading ] = useState(true);

    const { usuarioFilter, filtrar, temFiltro } = useContext(UsuarioFilterContext);

    const [ usuarios, setUsuarios ] = useState([]);

    const [ page, setPage ] = useState(0);
    const [ infoPagina, setInfoPagina ] = useState({});

    const handleEditarUsuario = (idUsuario) => {
        // Função que direciona para a página de edição do usuário
        window.location.href = `/editar-usuario/${idUsuario}`;
    }

    useEffect(() => {
        async function fetchUsuarios() {
            let response = null;
            setLoading(true);

            if(temFiltro()) {
                response = await getUsuariosFiltrados(usuarioFilter, page);
            } else {
                response = await getUsuarios(page);
            }
            
            setUsuarios(response.content);
            setInfoPagina(response);
            setLoading(false);
        }

        fetchUsuarios();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, filtrar]);

    return (
        <div>
            {loading && <Loading />}
            <div className="quantitativo-resultados-encontrados-list-view-usuarios">
                <span className="quantidade-usuarios-encontradas">{infoPagina.totalElements}</span>
                <p className="texto-resultado-usuarios-encontradas">Resultados Encontrados</p>
            </div>

            {infoPagina.totalElements === 0 
                ? (
                    <div className="container-list-view-usuario-nao-encontrado">
                        <NenhumEncontrado />
                    </div>
                ) : (
                    <div className="container-list-view-empresa">
                        <HeaderListViewUsuario />
                        {usuarios.map((usuario, index) => {
                            return (
                                <Usuario onEdit={handleEditarUsuario} key={index} usuario={usuario} />
                            )
                        })}

                        <PaginacaoPadrao
                            page={page}
                            setCurrentPage={setPage}
                            lastPage={infoPagina.totalPages}
                        />
                    </div>
                )}
        </div>
    )
}

const NenhumEncontrado = () => {
    return (
        <div className="nenhuma-usuario-encontrada-container">
            <IoIosWarning 
                className="icone-usuario-na-encontrada"
            />
            <h2 className="h2-nenhum-resultado-encontrado">NENHUM RESULTADO ENCONTRADO</h2>
            <p className="referencia-cadastro-nova-empresa">Tente colocar outros filtros, ou <a href="/cadastrar-usuario">cadastre um novo usuário</a>.</p>
        </div>
    )
}

const HeaderListViewUsuario = () => {
    return (
        <div className="header-list-view-usuario">
            <span className="header-acao">AÇÃO</span>
            <span className="header-nome-usuario">NOME DO USUÁRIO</span>
            <span className="header-tipo-usuario">TIPO DO USUÁRIO</span>
            <span className="header-situacao-usuario">SITUAÇÃO</span>
            <span className="header-cpf-usuario">CPF</span>
            <span className="header-email-usuario">EMAIL</span>
        </div>
    )
}

const Usuario = ({usuario, onEdit}) => {

    const cpfNaoCadastrado = "Não cadastrado";

    const formataCPF = (cpf) => {
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    }
    
    return (
        <div className="usuario-list-view-usuario">
            <span className="acao-usuario">
                <IconeEditar
                    onClick={onEdit.bind(this, usuario.id)}
                />
            </span>
            <span className="nome-usuario">{usuario.nome}</span>
            <span className="tipo-do-usuario">{usuario.tipoUsuario}</span>
            <span className="situacao-cadastro-usuario">
                <SituacaoCadastro
                    situacao={usuario.situacaoCadastro}
                />
            </span>
            <span className="cpf-usuario">
                {usuario.cpf !== "" ? formataCPF(usuario.cpf) : cpfNaoCadastrado}
            </span>
            <span className="email-usuario">{usuario.email}</span>
        </div>
    )
}

const IconeEditar = ({
    onClick
}) => {
    return (
        <HiPencil
            className="icone-editar-usuario"
            onClick={onClick}
        />
    )
}

export default ListViewUsuario