import MenuLateral from "../components/MenuLateral/MenuLateral";
import Header from "../components/Header/Header";
import UsuariosContainer from "../components/UsuariosContainer/UsuariosContainer";
import { UsuarioFilterProvider } from "../context/UsuarioFilterContext";

export default function Usuarios() {
  return (
    <div className="main-container">
      <UsuarioFilterProvider>
        <Header />
        <MenuLateral telaAtual={"usuarios"} />
        <UsuariosContainer />
      </UsuarioFilterProvider>
    </div>
  )
}
