// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.botao-padrao-custom {
    color: var(--branco-padrao);
    background: var(--azul-dixi);
    font-weight: 500;
    font-size: .9rem;
    padding: .7rem 1.5rem;
    width: max-content;
    min-width: 13rem;
    border-radius: 8px;
    border: none;
    transition: all .3s;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    max-height: 2.5rem;
}

.botao-padrao-custom svg {
    font-size: 1rem;
    margin-bottom: .10rem;
}

.botao-padrao-custom:hover {
    background: var(--botao-hover);
}

.botao-padrao-custom-cancelar {
    background-color: var(--branco-padrao);
    outline: 2px solid var(--azul-dixi);
    color: var(--azul-dixi);
    padding: .65rem 1.5rem;
}

.botao-padrao-custom-cancelar:hover {
    background-color: var(--azul-dixi);
    color: var(--branco-padrao);
}

.botao-padrao-custom-disabled {
    background: var(--cinza-padrao2);
    outline: none;
    color: var(--branco-padrao);
    cursor: not-allowed;
}

.botao-padrao-custom-disabled:hover {
    background: var(--cinza-padrao2);
}

.tirar-estilo-link {
    text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/components/BotaoCustom/BotaoCustom.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,4BAA4B;IAC5B,gBAAgB;IAChB,gBAAgB;IAChB,qBAAqB;IACrB,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,YAAY;IACZ,mBAAmB;IACnB,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,SAAS;IACT,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,qBAAqB;AACzB;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,sCAAsC;IACtC,mCAAmC;IACnC,uBAAuB;IACvB,sBAAsB;AAC1B;;AAEA;IACI,kCAAkC;IAClC,2BAA2B;AAC/B;;AAEA;IACI,gCAAgC;IAChC,aAAa;IACb,2BAA2B;IAC3B,mBAAmB;AACvB;;AAEA;IACI,gCAAgC;AACpC;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".botao-padrao-custom {\n    color: var(--branco-padrao);\n    background: var(--azul-dixi);\n    font-weight: 500;\n    font-size: .9rem;\n    padding: .7rem 1.5rem;\n    width: max-content;\n    min-width: 13rem;\n    border-radius: 8px;\n    border: none;\n    transition: all .3s;\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: 1rem;\n    max-height: 2.5rem;\n}\n\n.botao-padrao-custom svg {\n    font-size: 1rem;\n    margin-bottom: .10rem;\n}\n\n.botao-padrao-custom:hover {\n    background: var(--botao-hover);\n}\n\n.botao-padrao-custom-cancelar {\n    background-color: var(--branco-padrao);\n    outline: 2px solid var(--azul-dixi);\n    color: var(--azul-dixi);\n    padding: .65rem 1.5rem;\n}\n\n.botao-padrao-custom-cancelar:hover {\n    background-color: var(--azul-dixi);\n    color: var(--branco-padrao);\n}\n\n.botao-padrao-custom-disabled {\n    background: var(--cinza-padrao2);\n    outline: none;\n    color: var(--branco-padrao);\n    cursor: not-allowed;\n}\n\n.botao-padrao-custom-disabled:hover {\n    background: var(--cinza-padrao2);\n}\n\n.tirar-estilo-link {\n    text-decoration: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
