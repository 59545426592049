// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
    position: fixed;
    width: 100%;
    height: 3rem;
    background-color: var(--azul-dixi);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding-right: 1.1rem;
    z-index: 3000;
    box-shadow: 0 0 1rem rgba(0, 0, 0, .2);
}

.usuario-logado-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .6rem;
    padding: .2rem 1rem;
    width: max-content;
    cursor: pointer;
    transition: all .3s;
    border-radius: 8px;
}

.usuario-logado-container:hover {
    background-color: var(--botao-hover);
}

.usuario-logado-container .info-usuario-logado {
    text-align: right;
    font-size: .7rem;
    color: var(--branco-padrao);
}

.container-lateral-header {
    display: flex;
    align-items: center;
    gap: 0.4rem;
}

/* .usuario-logado-container .img-usuario-logado {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
}

.usuario-logado-container .img-usuario-logado img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
} */`, "",{"version":3,"sources":["webpack://./src/components/Header/Header.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,WAAW;IACX,YAAY;IACZ,kCAAkC;IAClC,aAAa;IACb,sBAAsB;IACtB,qBAAqB;IACrB,uBAAuB;IACvB,qBAAqB;IACrB,aAAa;IACb,sCAAsC;AAC1C;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,UAAU;IACV,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;IACf,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,2BAA2B;AAC/B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;AACf;;AAEA;;;;;;;;;;GAUG","sourcesContent":[".header {\n    position: fixed;\n    width: 100%;\n    height: 3rem;\n    background-color: var(--azul-dixi);\n    display: flex;\n    flex-direction: column;\n    align-items: flex-end;\n    justify-content: center;\n    padding-right: 1.1rem;\n    z-index: 3000;\n    box-shadow: 0 0 1rem rgba(0, 0, 0, .2);\n}\n\n.usuario-logado-container {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: .6rem;\n    padding: .2rem 1rem;\n    width: max-content;\n    cursor: pointer;\n    transition: all .3s;\n    border-radius: 8px;\n}\n\n.usuario-logado-container:hover {\n    background-color: var(--botao-hover);\n}\n\n.usuario-logado-container .info-usuario-logado {\n    text-align: right;\n    font-size: .7rem;\n    color: var(--branco-padrao);\n}\n\n.container-lateral-header {\n    display: flex;\n    align-items: center;\n    gap: 0.4rem;\n}\n\n/* .usuario-logado-container .img-usuario-logado {\n    width: 2rem;\n    height: 2rem;\n    border-radius: 50%;\n}\n\n.usuario-logado-container .img-usuario-logado img {\n    width: 100%;\n    height: 100%;\n    border-radius: 50%;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
