import './EditarChamadoContainer.css';
import ConfigEditarChamado from '../ConfigEditarChamado/ConfigEditarChamado';
import FormEditarChamado from '../FormEditarChamado/FormEditarChamado';
import { useContext, useEffect, useState } from 'react';
import { getChamadoById } from '../../services/ticketService';
import { EditTicketContext } from '../../context/EditTicketContext';
import ComentarioChamado from '../ComentarioChamado/ComentarioChamado';
import ListaComentarios from '../ListaComentarios/ListaComentarios';
import Loading from '../Loading/Loading';

function EditarChamadoContainer({ idChamado }) {

    const [ loading, setLoading ] = useState(true);

    const { setTicketData, ticketData, setDepartamentoEscolhido, verificaSePodeEditarEsseChamado } = useContext(EditTicketContext);

    useEffect(() => {
        getChamadoById(idChamado).then((chamado) => {
            if(chamado === null) {
                window.location.href = '/nao-encontrado';
                return;
            }
            setTicketData(chamado);
            setDepartamentoEscolhido(chamado.departamentoEmpresa);
            verificaSePodeEditarEsseChamado(chamado.usuarioResponsavelAtual);
            setLoading(false);
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="container-editar-chamado">
            {loading && <Loading />}
            
            <div className="editar-chamado-esquerda">
                <FormEditarChamado />
                <ComentarioChamado chamado={ticketData} />
                <ListaComentarios comentarios={ticketData.comentarios} />
            </div>
            
            <ConfigEditarChamado />
        </div>
    )
}

export default EditarChamadoContainer