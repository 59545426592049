import FiltroUsuarioContainer from '../FiltroUsuarioContainer/FiltroUsuarioContainer';
import ListViewUsuario from '../ListViewUsuario/ListViewUsuario';
import './UsuariosContainer.css';

function UsuariosContainer() {
  return (
    <div className="container">
        <FiltroUsuarioContainer />
        <ListViewUsuario />
    </div>
  )
}

export default UsuariosContainer;