import './HeaderContainers.css';

function HeaderContainers({ texto, cinza, extra }) {
  return (
    <div className={`header-container ${cinza ? "cor-cinza" : ""}`}>
        <h2 className={`titulo-do-container`}>{texto.toUpperCase()}</h2>
        {extra ? <p className='extra-text-header'>{extra}</p> : ''}
    </div>
  );
}

export default HeaderContainers;