import { createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createEmpresa } from '../services/empresaService';
import { validaCampoVazio, validaDocumento } from '../validations/basicsValidations';
import { montaResposta } from '../utils/ResponsesUtils';

export const EmpresaContext = createContext();

export const EmpresaProvider = ({ children }) => {
    const navigate = useNavigate();

    const [empresaData, setEmpresaData] = useState({
        razaoSocial: '',
        sigla: '',
        tipoDocumento: '',
        numDocumento: '',
        situacaoCadastro: '',
    });

    // Função para atualizar o estado da empresa que está sendo cadastrada (um por vez)
    const handleChange = (field, value) => {
        setEmpresaData(estadoAnterior => ({
            ...estadoAnterior,
            [field]: value,
        }));
    };

    const handleSubmit = async () => {
        // VALIDAÇÕES DE EMPRESA
        const cnpjValido = validaDocumento(empresaData.tipoDocumento, empresaData.numDocumento);
        const razaoSocialValida = validaCampoVazio(empresaData.razaoSocial);
        const siglaValida = validaCampoVazio(empresaData.sigla);
        const situacaoCadastroValida = validaCampoVazio(empresaData.situacaoCadastro);

        if(cnpjValido 
            && razaoSocialValida 
            && siglaValida 
            && situacaoCadastroValida) {
            
            try {
                empresaData.sigla = empresaData.sigla.toUpperCase();
                empresaData.numDocumento = empresaData.numDocumento.replace(/[^\d]+/g,'');
            } catch (error) {
                console.error(error);
                return null;
            }

            return await createEmpresa(empresaData);
        } else {
            // Retorna todos os campos que estão inválidos
            return montaResposta(
                undefined, 
                'Preencha corretamente os campos obrigatórios.',
                null,
                { cnpjValido, razaoSocialValida, siglaValida,situacaoCadastroValida });
        }
    };

    const handleCancelling = () => {
        handleClear();
        irParaListViewEmpresa();
    };

    const irParaListViewEmpresa = () => {
        navigate('/empresas');
        window.location.reload();
    };

    const handleClear = () => {
        setEmpresaData(() => ({
            razaoSocial: '',
            sigla: '',
            tipoDocumento: '',
            numDocumento: '',
            situacaoCadastro: '',
        }));
    };

    return (
        <EmpresaContext.Provider 
            value={{ empresaData, handleChange, handleSubmit, handleCancelling, handleClear }}>
            {children}
        </EmpresaContext.Provider>
    )
}
