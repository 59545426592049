import React from 'react'
import Header from '../components/Header/Header'
import MenuLateral from '../components/MenuLateral/MenuLateral'
import FormCadastroDepartamento from '../components/FormCadastroDepartamento/FormCadastroDepartamento'
import { DepartamentoProvider } from '../context/DepartamentoContext'

function CadastroDepartamento() {
  return (
    <DepartamentoProvider>
      <div className="main-container">
        <Header />
        <MenuLateral telaAtual={"departamentos"} />
        <FormCadastroDepartamento />
      </div>
    </DepartamentoProvider>
  )
}

export default CadastroDepartamento