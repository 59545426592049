import api from "./api";

const endpoint = "/notificacao_";

export const getNotificacaoByUsuario = async (idUsuario) => {
    try {
        const response = await api.get(endpoint + "/" + idUsuario);
        return response.data;
    } catch (error) {
        return null;
    }
}

export const getTemNotificacao = async (idUsuario) => {
    try {
        const response = await api.get(endpoint + "/tem/" + idUsuario);
        return response.data;
    } catch (error) {
        return null;
    }
}

export const marcaComoLida = async (idNotificacao) => {
    try {
        const response = await api.put(endpoint + "/lerNotificacao/" + idNotificacao);
        return response.data;
    } catch (error) {
        return null;
    }
}

export const marcarTodasComoLidas = async (idUsuario) => {
    try {
        const response = await api.put(endpoint + "/lerTodasNotificacoes/" + idUsuario);
        return response.data;
    } catch (error) {
        return null;
    }
}