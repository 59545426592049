import { montaCaminho } from "../utils/DataUtils";
import api from "./api";

const endpoint = '/imagem_';

export const saveAllImagem = async (imagens, chamado, anexosParametro) => {
    let response = false;
    let quantidadeArquivos = Math.max(imagens.length, anexosParametro.length);

    try {
        for (let index = 0; index < quantidadeArquivos; index++) {

            let anexo = {
                nomeArquivo: anexosParametro[index] ? anexosParametro[index].file.name : "",
                base64: anexosParametro[index] ? anexosParametro[index].url.split(",")[1] : "",
                tipoArquivo: anexosParametro[index] ? anexosParametro[index].file.type.split('/').pop() : ""
            };

            if (anexosParametro[index]?.file.type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
                anexo.tipoArquivo = "docx";
            } else if (anexosParametro[index]?.file.type === "application/pdf") {
                anexo.tipoArquivo = "pdf";
            } else if (anexosParametro[index]?.file.type === "text/plain") {
                anexo.tipoArquivo = "txt";
            } else if (anexosParametro[index]?.url.type === "data:text/csv") {
                anexo.tipoArquivo = "csv";
            }

            if(imagens[index]) {
                let imagemDTo = {
                    urlImagens : imagens[index].url.split(",")[1],
                    tituloImagem : imagens[index].file.name
                }

                response = await api.post(endpoint + "/all", [{
                    chamado: chamado,
                    anexos: [],
                    imagemDTO: [imagemDTo]
                }]);
            }

            if(anexosParametro[index]) {
                response = await api.post(endpoint + "/all", [{
                    chamado: chamado,
                    anexos: [anexo],
                    imagemDTO: []
                }]);
            }
        }

        if(response.data != null) {
            return response.data;
        }
    } catch (error) {
        console.error(error);
        return null;
    }
};

export const getImagem = async (url) => {
    try {
        const response = await api.get(montaCaminho(url), {
            responseType: 'blob'
        });

        const blob = new Blob([response.data], { type: response.data.type });

        //criando um link temporário para baixar o arquivo
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);        
        link.setAttribute('download', url.split('/').pop());
        
        //adiciona o link no corpo do documento
        document.body.appendChild(link);
        link.click();
        
        //remove o link do corpo do documento e sinaliza o usuário
        URL.revokeObjectURL(link.href);
        document.body.removeChild(link);

        return "Download concluído!";
    } catch (e) {
        console.error('Erro no download:', e);
        return "Erro ao recuperar imagem";
    }
}