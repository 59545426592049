import './FormAbrirChamado.css'
import { IoDocumentText, IoClose } from "react-icons/io5";
import { IoIosAttach } from "react-icons/io";
import { LuImagePlus } from "react-icons/lu";
import CampoObrigatorio from '../CampoObrigatorio/CampoObrigatorio';
import { TicketContext } from '../../context/TicketContext';
import { useContext } from 'react';
import HeaderContainers from '../HeaderContainers/HeaderContainers';
import Loading from '../Loading/Loading';
import Anexo from '../Anexo/Anexo';

function FormAbrirChamado() {

    const { ticketData, handleChange, handleFileChange, handleFileRemove, loading } = useContext(TicketContext);

    const mudarAssunto = "assunto";
    const mudarDescricao = "descricao";

    return (
        <div className="criar-novo-ticket-container">
            {loading && <Loading />}
            <HeaderContainers texto="abrir chamado" />

            <div className="formulario-abrir-novo-chamado">
                <form>
                    <div className="container-input-chamado">
                        <label htmlFor="assunto-chamado">
                            ASSUNTO <CampoObrigatorio />
                        </label>
                        <input  type="text" id='assunto-chamado' name="assunto-chamado" onChange={(e) => {handleChange(mudarAssunto, e.target.value)}} placeholder='Escreva um titulo para este chamado.'/>   
                    </div>

                    <div className="container-input-chamado">
                        <label htmlFor="descricao-chamado">
                            <IoDocumentText className="icon-azul" />
                            DESCRIÇÃO DO CHAMADO <CampoObrigatorio />
                        </label>

                        {ticketData.imagens.length > 0 
                            && <ImagensSelecionadas
                                    imagens={[ticketData.imagens]}
                                    onRemove={(index) => handleFileRemove(index, 'imagens')}
                                />}

                        <textarea onChange={(e) => {handleChange(mudarDescricao, e.target.value)}} id="descricao-chamado" name="descricao-chamado" rows="7" cols="50" placeholder='Descreva a situação do chamado.'>
                        </textarea>
                    </div>

                    <div className="anexos-e-adicionais-container">
                        <div className="container-selecionar-arquivos">
                            <div className="container-input-chamado">
                                <label className="input-file-label" htmlFor="anexo-chamado">
                                    <IoIosAttach />
                                </label>
                                <input onChange={(e) => {handleFileChange(e, 'attachment')}} type="file" id="anexo-chamado" name="anexo-chamado" style={{ display: 'none' }} />
                            </div>

                            <div className="container-input-chamado">
                                <label className="input-file-label" htmlFor="imagem-chamado">
                                    <LuImagePlus />
                                </label>
                                <input onChange={(e) => {handleFileChange(e, 'imagens')}} type="file" id="imagem-chamado" name="imagem-chamado" style={{ display: 'none' }} />
                            </div>
                        </div>

                        <div className="informacoes-anexos-adicionados">
                            <p className="quantidade-arquivos-adicionados">{ticketData.imagens.length + ticketData.anexos.length}</p>
                            <p className="textos-arquivos-adicionados">ARQUIVOS ADICIONADOS</p>
                        </div>
                    </div>
                </form>

                {ticketData.anexos.length > 0 
                && <AnexosSelecionados
                        anexos={ticketData.anexos}
                        onRemove={(index) => handleFileRemove(index, 'anexos')}
                    />}

            </div>
        </div>
    )
}

const ImagensSelecionadas = ({
    imagens,
    onRemove
}) => {

    return (
        <div className="imagens-adicionadas-pelo-usuario">
            {imagens.flat().map((imagem, index) => (
            <div className="container-imagem-selecionada" key={index}>
                <IoClose className='botao-remover-imagem' onClick={() => onRemove(index)} />
                <img className="imagem-selecionada-pelo-usuario-chamado" src={imagem.url} alt="Arquivo Selecionado" />
            </div>
            ))}
        </div>
    )
}

const AnexosSelecionados = ({ anexos, onRemove }) => {

    return (
        <div className='container-global-anexos'>
            <p className='escrita-anexos'>ANEXOS</p>
            <hr className='linha-anexos'></hr>
            <div className='container-anexos'>
                {anexos.map((anexo, index) => {
                    return (
                        <div key={index} className="container-anexo-selecionado">
                            <IoClose className='botao-remover-anexo' onClick={() => onRemove(index)}/>
                            <div className='container-interno-anexo'>
                                <Anexo
                                    tipoDeArquivo={anexo.file.type}
                                />
                                <div className='informacoes-internas-anexo'>
                                    <p className="nome-anexo-selecionado-pelo-usuario-chamado" title={anexo.file.name}>
                                        {anexo.file.name}
                                    </p>
                                    <p className="texto-tamanho-anexo-selecionado" title={anexo.file.size}>
                                        {(anexo.file.size / 1024).toFixed(2)} KB
                                    </p>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
}

export default FormAbrirChamado