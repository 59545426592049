// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.campo-obrigatorio-abertura-chamado {
    color: var(--vermelho-padrao);
    font-size: .8rem;
    font-weight: 600;
    margin-left: 0.1rem;
}`, "",{"version":3,"sources":["webpack://./src/components/CampoObrigatorio/CampoObrigatorio.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".campo-obrigatorio-abertura-chamado {\n    color: var(--vermelho-padrao);\n    font-size: .8rem;\n    font-weight: 600;\n    margin-left: 0.1rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
