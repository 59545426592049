import './CamposInvalidos.css'

function CamposInvalidos() {
  return (
    <div className="container-erros-em-campos">
        <p className="mensagem-erro-campos-invalidos">CAMPOS INVÁLIDOS!</p>
    </div>
  )
}

export default CamposInvalidos