import { createContext, useState } from 'react';
import { createUsuario } from '../services/usuarioService';
import { useNavigate } from 'react-router-dom';
import { validaCampoVazio, validaCPF } from '../validations/basicsValidations';
import { validaConfirmaSenha, validaEmail, validaSenha } from '../validations/basicAuthValidations';
import { montaResposta } from '../utils/ResponsesUtils';
import { createDepartamentoEmpresaUsuario } from '../services/departamentoEmpresaUsuarioService';

export const UsuarioContext = createContext();

export const UsuarioProvider = ({ children }) => {
    const navigate = useNavigate();

    const [usuarioData, setUsuarioData] = useState({
        perfilUsuario: '',
        nome: '',
        email: '',
        senha: '',
        confirmarSenha: '',
        cpf: '',
        situacaoCadastro: ''
    });

    const [departamento, setDepartamento] = useState(null);

    // Função para atualizar o estado do usuario que está sendo cadastrada (um por vez)
    const handleChange = (field, value) => {

        if(field == "departamento") {
            setDepartamento(value)
            return;
        }

        setUsuarioData(estadoAnterior => ({
            ...estadoAnterior,
            [field]: value,
        }));
    };

    const handleSubmit = async () => {
        // Faz todas as validações para verificar se os campos estão corretamente preenchidos
        const nomeValido = validaCampoVazio(usuarioData.nome);
        const emailValido = validaEmail(usuarioData.email);
        const senhaValida = validaSenha(usuarioData.senha);
        const confirmarSenhaValida = validaConfirmaSenha(usuarioData.senha, usuarioData.confirmarSenha);
        const cpfValido = validaCPF(usuarioData.cpf, false);
        const situacaoCadastroValida = validaCampoVazio(usuarioData.situacaoCadastro);
        const perfilUsuarioValido = validaCampoVazio(usuarioData.perfilUsuario);
        const departamentoValido = validaCampoVazio(departamento);

        if(nomeValido 
            && emailValido
            && senhaValida
            && confirmarSenhaValida
            && cpfValido
            && situacaoCadastroValida
            && perfilUsuarioValido
            && departamentoValido) {

            try {
                usuarioData.cpf = usuarioData.cpf.replace(/[^\d]+/g,'');
            } catch (error) {
                console.error(error);
            }

            const response = await createUsuario(usuarioData);

            if(response.success) {
                // Requisição para criar o departamentoEmpresaUsuario
                let departamentoEmpresaUsuario = {
                    departamento: departamento.departamento,
                    empresa: departamento.empresa,
                    usuario: response.data
                };

                departamentoEmpresaUsuario = await createDepartamentoEmpresaUsuario(departamentoEmpresaUsuario);

                if(departamentoEmpresaUsuario) {
                    // Retorna todos os campos que estão inválidos
                    return montaResposta(
                        true, 
                        'Usuário cadastrado com sucesso.',
                        null,
                        null);
                }
            } else {
                return response;
            }
        } else {
            // Retorna todos os campos que estão inválidos
            return montaResposta(
                undefined, 
                'Preencha corretamente os campos obrigatórios.',
                null,
                { nomeValido,
                    emailValido,
                    senhaValida,
                    confirmarSenhaValida,
                    cpfValido,
                    situacaoCadastroValida,
                    perfilUsuarioValido,
                    departamentoValido });
        }
    }

    const handleCancelling = () => {
        setUsuarioData(() => ({
            idPerfilUsuario: '',
            nome: '',
            email: '',
            senha: '',
            confirmarSenha: '',
            cpf: '',
            idSituacaoCadastro: ''
        }));

        irParaListViewUsuario();
    }

    const irParaListViewUsuario = () => {
        navigate('/usuarios');
    };

    return (
        <UsuarioContext.Provider 
            value={{ usuarioData, handleChange, handleSubmit, handleCancelling }}>
            {children}
        </UsuarioContext.Provider>
    )
}
