import './ListaChamados.css';
import { ExpansionList } from "@react-md/expansion-panel";
import PainelExpansivelChamado from '../PainelExpansivelChamado/PainelExpansivelChamado';
import { useEffect, useState } from 'react';
import { getStatus } from '../../services/statusService';
import { useAuth } from '../../services/AuthContext';
import Loading from '../Loading/Loading';

function ListaChamados() {

    const { user, logout } = useAuth();

    const [status, setStatus] = useState(null);
    const [contador, setContador] = useState(0);
    const [quantidadeContador, setQuantidadeContador] = useState(0);
    
    useEffect(() => {
        const fetchStatus = async () => {
            const statusFetched = await getStatus();

            if(statusFetched == null) {
                logout();
                return;
            }

            setQuantidadeContador(statusFetched.length);
            setStatus(statusFetched);
        };
    
        fetchStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    if(status == null) {
        return <Loading />
    }

    return (
        <div className="expansion-list-container">
            <ExpansionList className="lista-tipo-chamado">
                {status.map((status, index) => (
                    <PainelExpansivelChamado
                        key={`${status.idStatus}-${index}`}
                        status={status}
                        contador={contador}
                        quantidadeContador={quantidadeContador}
                        setContador={setContador}
                    />
                ))}
            </ExpansionList>
        </div>
    );
}

export default ListaChamados;
