import React from 'react'
import './Anexo.css'
import { IoDocumentTextOutline } from 'react-icons/io5';
import { FaFilePdf, FaFileWord, FaFileImage, FaFileCsv, FaFileAlt, FaFileExcel } from 'react-icons/fa'; 

function Anexo({ tipoDeArquivo, telaOrigem }) {

    let IconComponent;

        switch (true) {
            case tipoDeArquivo === 'application/pdf' || tipoDeArquivo === 'pdf':
                IconComponent = FaFilePdf;
                break;

            case tipoDeArquivo.startsWith('image/'):
                IconComponent = FaFileImage;
                break;

            case tipoDeArquivo === 'application/msword' ||
                tipoDeArquivo === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                IconComponent = FaFileWord;
                break;

            case tipoDeArquivo === 'text/csv' || tipoDeArquivo === 'csv':
                IconComponent = FaFileCsv;
                break;

            case tipoDeArquivo === 'text/plain' || tipoDeArquivo === 'txt':
                IconComponent = FaFileAlt;
                break;

            case tipoDeArquivo === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                IconComponent = FaFileExcel;
                break;

            default:
                IconComponent = IoDocumentTextOutline;
        }
    
  return (
    <IconComponent size={32} className='imagem-previa-conteudo-anexo' />
  )
}

export default Anexo