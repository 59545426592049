import { createContext, useState } from "react";

export const DepartamentoFilterContext = createContext();

export const DepartamentoFilterProvider = ({ children }) => {
    const [departamentoFilter, setDepartamentoFilter] = useState({
        nomeDepartamento: '',
        sigla: '',
        situacaoCadastro: ''
    });

    const [filtrar, setFiltrar] = useState(false);

    const handleChange = (inputPara, value) => {
        let novoDepartamentoFilter = {
            nomeDepartamento: departamentoFilter.nomeDepartamento,
            sigla: departamentoFilter.sigla,
            situacaoCadastro: departamentoFilter.situacaoCadastro,
        }

        novoDepartamentoFilter[inputPara] = retornaValorFiltro(inputPara, value);
        setDepartamentoFilter(novoDepartamentoFilter);
    };

    const handleClear = () => {
        setDepartamentoFilter({
            nomeDepartamento: '',
            sigla: '',
            situacaoCadastro: ''
        });
    }

    const temFiltro = () => {
        return departamentoFilter.nomeDepartamento !== '' 
            || departamentoFilter.sigla !== ''
            || departamentoFilter.situacaoCadastro !== '';
    }

    const handleSearch = () => {
        setFiltrar(!filtrar);
    };

    return (
        <DepartamentoFilterContext.Provider value={{ 
            departamentoFilter, 
            temFiltro,
            handleChange, 
            filtrar, 
            setFiltrar, 
            handleSearch,
            handleClear
        }}>
            {children}
        </DepartamentoFilterContext.Provider>
    );
};

const retornaValorFiltro = (inputPara, filtro) => {

    let retorno = "";

    switch(inputPara) {
        case "nomeDepartamento":
            retorno = filtro;
            break;
        case "situacaoCadastro":
            if(filtro === "") {
                retorno = "";
            } else {
                retorno = filtro.idSituacaoCadastro;
            }
            break;
        case "sigla":
            retorno = filtro;
            break;
        default:
            retorno = "erro";
            break;
    };

    return retorno;
};