import { createContext, useState } from 'react';
import { getUsuarioById, updateUsuario } from '../services/usuarioService';
import { useNavigate } from 'react-router-dom';
import { validaCampoVazio, validaCPF } from '../validations/basicsValidations';
import { validaConfirmaSenha, validaEmail } from '../validations/basicAuthValidations';
import { montaResposta } from '../utils/ResponsesUtils';
import { createDepartamentoEmpresaUsuario, updateDepartamentoEmpresaUsuario } from '../services/departamentoEmpresaUsuarioService';

export const EditarUsuarioContext = createContext();

export const EditarUsuarioProvider = ({ children }) => {
    const navigate = useNavigate();

    const initState = {
        idUsuario: 0,
        perfilUsuario: '',
        nome: '',
        email: '',
        senha: '',
        confirmarSenha: '',
        cpf: '',
        situacaoCadastro: ''
    };

    const [ departamentoEmpresaUsuarioVinculado, setDepartamentoEmpresaUsuarioVinculado ] = useState(null);
    const [ listaDepartamentosTela, setListaDepartamentosTela ] = useState([]);

    const [ mudou, setMudou ] = useState(false);
    const [ mudouDepartamento, setMudouDepartamento ] = useState(false);

    const [ lastUsuarioData, setLastUsuarioData ] = useState(null);
    const [ lastDepartamentoData, setLastDepartamentoData ] = useState(null);

    const [usuarioData, setUsuarioData] = useState(initState);
    const [departamento, setDepartamento] = useState(null);

    // Função para atualizar o estado do usuario que está sendo cadastrada (um por vez)
    const handleChange = (field, value) => {

        if(!mudou) {
            setLastUsuarioData(usuarioData);
        }
        setMudou(true);

        if(field === "departamento") {
            if(!mudouDepartamento) {
                setLastDepartamentoData(departamento)
            }
            setDepartamento(value)
            setMudouDepartamento(true);
            return;
        }

        setUsuarioData(estadoAnterior => ({
            ...estadoAnterior,
            [field]: value,
        }));
    };

    const handleSubmitDpto = async () => {
        let departamentoEmpresaUsuario = {
            departamento: departamento.departamento,
            empresa: departamento.empresa,
            usuario: usuarioData
        };

        departamentoEmpresaUsuario = await createDepartamentoEmpresaUsuario(departamentoEmpresaUsuario);

        if(departamentoEmpresaUsuario) {
            let novaLista = listaDepartamentosTela;
            novaLista.push(departamentoEmpresaUsuario.departamento);
            setListaDepartamentosTela(novaLista);

            return montaResposta(
                true, 
                'Vínculo de departamento adicionado com sucesso!',
                null,
                null);
        } else {
            return montaResposta(
                true, 
                'EasterEgg!!!',
                null,
                null);
        }
    };

    const handleSubmit = async () => {
        // Verifica se houve mudança de informações
        const mudouInfoUsuario = verificaSeMudouInfoUsuario(usuarioData, lastUsuarioData);

        // Faz todas as verificações para editar o usuário
        const nomeValido = validaCampoVazio(usuarioData.nome);
        const emailValido = validaEmail(usuarioData.email);
        const senhaValida = validaCampoVazio(usuarioData.senha);

        // Trata o confirmar senha (porque vem vazio do banco)
        let confirmarSenha = usuarioData.confirmarSenha === undefined ? usuarioData.senha : usuarioData.confirmarSenha;

        const confirmarSenhaValida = validaConfirmaSenha(usuarioData.senha, confirmarSenha);
        const cpfValido = validaCPF(usuarioData.cpf, false);
        const situacaoCadastroValida = validaCampoVazio(usuarioData.situacaoCadastro);
        const perfilUsuarioValido = validaCampoVazio(usuarioData.perfilUsuario);

        if(mudouInfoUsuario) {
            if(nomeValido 
                && emailValido
                && senhaValida
                && confirmarSenhaValida
                && cpfValido
                && situacaoCadastroValida
                && perfilUsuarioValido) {

                // Faz a requisição para editar o usuário
                const retorno = await updateUsuario(usuarioData);

                if(retorno.success) setLastUsuarioData(null);

                return retorno;
            } else {
                // Retorna todos os campos que estão inválidos
                return montaResposta(
                undefined, 
                'Preencha corretamente os campos obrigatórios.',
                null,
                { nomeValido: nomeValido,
                    emailValido: emailValido,
                    senhaValida: senhaValida,
                    confirmarSenhaValida: confirmarSenhaValida,
                    cpfValido: cpfValido,
                    situacaoCadastroValida: situacaoCadastroValida,
                    perfilUsuarioValido: perfilUsuarioValido });
            }
        } else {
            return null;
        }
    }

    const handleCancelling = () => {
        handleClear();
        irParaListViewUsuario();
    }

    const handleClear = () => {
        setUsuarioData(() => (initState));
    }

    const irParaListViewUsuario = () => {
        navigate('/usuarios');
    };

    return (
        <EditarUsuarioContext.Provider 
            value={{ usuarioData, setUsuarioData, handleChange, handleCancelling, handleSubmit, departamento, setDepartamento, handleSubmitDpto, setDepartamentoEmpresaUsuarioVinculado, listaDepartamentosTela, setListaDepartamentosTela }}>
            {children}
        </EditarUsuarioContext.Provider>
    )
}

const verificaSeMudouInfoUsuario = (usuarioData, lastUsuarioData) => {

    if(lastUsuarioData === null) {
        return false;
    }

    if(usuarioData.nome !== lastUsuarioData.nome) {
        return true;
    }

    if(usuarioData.email !== lastUsuarioData.email) {
        return true;
    }

    if(usuarioData.senha !== lastUsuarioData.senha) {
        return true;
    }

    if(usuarioData.cpf !== lastUsuarioData.cpf) {
        return true;
    }

    if(usuarioData.situacaoCadastro !== lastUsuarioData.situacaoCadastro) {
        return true;
    }

    if(usuarioData.perfilUsuario !== lastUsuarioData.perfilUsuario) {
        return true;
    }

    return false;
};

const verificaSeMudouInfoDepartamento = (departamento, lastDepartamentoData) => {
    if(lastDepartamentoData === null) {
        return false;
    }

    if(departamento.nomeDepartamento !== lastDepartamentoData.nomeDepartamento) {
        return true;
    }

    return false;
};
