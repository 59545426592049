// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-container {
    display: flex;
    background-color: var(--azul-dixi);
    color: var(--branco-padrao);
    font-size: .7rem;
    padding: .5rem .8rem;
    border-radius: 8px 8px 0 0;
    width: 100%;
    text-wrap: stable;
    align-items: center;
}

.cor-cinza {
    background-color: var(--cinza-em-aberto);
}

.extra-text-header {
    font-size: 1rem;
    margin-left: .5rem;
    font-weight: bold;
    color: var(--text-color3);
}
`, "",{"version":3,"sources":["webpack://./src/components/HeaderContainers/HeaderContainers.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kCAAkC;IAClC,2BAA2B;IAC3B,gBAAgB;IAChB,oBAAoB;IACpB,0BAA0B;IAC1B,WAAW;IACX,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI,wCAAwC;AAC5C;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,iBAAiB;IACjB,yBAAyB;AAC7B","sourcesContent":[".header-container {\n    display: flex;\n    background-color: var(--azul-dixi);\n    color: var(--branco-padrao);\n    font-size: .7rem;\n    padding: .5rem .8rem;\n    border-radius: 8px 8px 0 0;\n    width: 100%;\n    text-wrap: stable;\n    align-items: center;\n}\n\n.cor-cinza {\n    background-color: var(--cinza-em-aberto);\n}\n\n.extra-text-header {\n    font-size: 1rem;\n    margin-left: .5rem;\n    font-weight: bold;\n    color: var(--text-color3);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
