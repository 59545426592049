// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.expansion-list-container {
    margin: 1rem 0;
    padding-bottom: 1rem;
}

.lista-tipo-chamado {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}`, "",{"version":3,"sources":["webpack://./src/components/ListaChamados/ListaChamados.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,UAAU;AACd","sourcesContent":[".expansion-list-container {\n    margin: 1rem 0;\n    padding-bottom: 1rem;\n}\n\n.lista-tipo-chamado {\n    display: flex;\n    flex-direction: column;\n    gap: .5rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
