import { useParams } from "react-router-dom";
import EditarChamadoContainer from "../components/EditarChamadoContainer/EditarChamadoContainer";
import Header from "../components/Header/Header";
import MenuLateral from "../components/MenuLateral/MenuLateral";
import { EditTicketProvider } from "../context/EditTicketContext";

const VisualizarChamado = () => {

    const { idChamado } = useParams();

    return (
        <EditTicketProvider>
            <div className="main-container">
                <Header />
                <MenuLateral telaAtual={"abrir-chamado"} />
                <EditarChamadoContainer idChamado={idChamado} />
            </div>
        </EditTicketProvider>
    );
}

export default VisualizarChamado;