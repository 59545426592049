// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-paginacao {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    gap: .5rem;
    font-weight: 500;
    font-size: 1.5rem;
    color: var(--texto-cinza);
    margin-top: 0.8rem;
}

.container-numeros-paginacao {
    display: flex;
    align-items: center;
    gap: .5rem;
}

.numero-pagina {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 6px;
    background-color: var(--cinza-claro-hover);
    color: var(--cinza-padrao2);
    font-size: 1.1rem;
    transition: all .1s ease-in-out;
}

.numero-pagina:hover {
    color: var(--branco-padrao);
    background-color: var(--text-color3);
}

.pagina-ativa, .pagina-ativa:hover {
    color: var(--branco-padrao);
    background-color: var(--azul-dixi);
}

.voltar-duplo-paginacao {
    cursor: pointer;
    font-size: 1.2rem;
    color: var(--texto-cinza);
}

.seta-padrao-paginacao:hover, .voltar-duplo-paginacao:hover {
    transform: scale(1.1);
    color: var(--azul-dixi);
    cursor: pointer;
}

.paginacao-indisponivel, .paginacao-indisponivel:hover {
    cursor: not-allowed;
    color: var(--cinza-desativado);
}`, "",{"version":3,"sources":["webpack://./src/components/PaginacaoPadrao/PaginacaoPadrao.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,uBAAuB;IACvB,UAAU;IACV,gBAAgB;IAChB,iBAAiB;IACjB,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,UAAU;AACd;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;IACf,aAAa;IACb,cAAc;IACd,kBAAkB;IAClB,0CAA0C;IAC1C,2BAA2B;IAC3B,iBAAiB;IACjB,+BAA+B;AACnC;;AAEA;IACI,2BAA2B;IAC3B,oCAAoC;AACxC;;AAEA;IACI,2BAA2B;IAC3B,kCAAkC;AACtC;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,yBAAyB;AAC7B;;AAEA;IACI,qBAAqB;IACrB,uBAAuB;IACvB,eAAe;AACnB;;AAEA;IACI,mBAAmB;IACnB,8BAA8B;AAClC","sourcesContent":[".container-paginacao {\n    display: flex;\n    align-items: center;\n    width: 100%;\n    justify-content: center;\n    gap: .5rem;\n    font-weight: 500;\n    font-size: 1.5rem;\n    color: var(--texto-cinza);\n    margin-top: 0.8rem;\n}\n\n.container-numeros-paginacao {\n    display: flex;\n    align-items: center;\n    gap: .5rem;\n}\n\n.numero-pagina {\n    display: flex;\n    text-align: center;\n    align-items: center;\n    justify-content: center;\n    cursor: pointer;\n    width: 1.8rem;\n    height: 1.8rem;\n    border-radius: 6px;\n    background-color: var(--cinza-claro-hover);\n    color: var(--cinza-padrao2);\n    font-size: 1.1rem;\n    transition: all .1s ease-in-out;\n}\n\n.numero-pagina:hover {\n    color: var(--branco-padrao);\n    background-color: var(--text-color3);\n}\n\n.pagina-ativa, .pagina-ativa:hover {\n    color: var(--branco-padrao);\n    background-color: var(--azul-dixi);\n}\n\n.voltar-duplo-paginacao {\n    cursor: pointer;\n    font-size: 1.2rem;\n    color: var(--texto-cinza);\n}\n\n.seta-padrao-paginacao:hover, .voltar-duplo-paginacao:hover {\n    transform: scale(1.1);\n    color: var(--azul-dixi);\n    cursor: pointer;\n}\n\n.paginacao-indisponivel, .paginacao-indisponivel:hover {\n    cursor: not-allowed;\n    color: var(--cinza-desativado);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
