import React from 'react'
import Header from '../components/Header/Header'
import MenuLateral from '../components/MenuLateral/MenuLateral'
import { EditarEmpresaProvider } from '../context/EditarEmpresaContext'
import FormEditarEmpresa from '../components/FormEditarEmpresa/FormEditarEmpresa'
import { useParams } from 'react-router-dom'

function EditarEmpresa() {

  const { idEmpresa } = useParams();

  return (
    <EditarEmpresaProvider>
      <div className="main-container">
        <Header />
        <MenuLateral telaAtual={"empresas"} />
        <FormEditarEmpresa idEmpresa={idEmpresa} />
      </div>
    </EditarEmpresaProvider>
  )
}

export default EditarEmpresa;