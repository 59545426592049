import logoImage from './dixi_ponto_banner.png';
import './LogoBanner.css';

const LogoBanner = () => {
    return (
        <div className="logo-banner">
            <img className='logo-banner-img' src={logoImage} alt="Logo Banner da Dixi Ponto" />
        </div>
    );
}

export default LogoBanner;
