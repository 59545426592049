// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-nenhuma-pagina-encontrada {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nenhum-encontrada-container {
    margin-bottom: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--texto-cinza);
}

.nenhum-encontrada-container svg {
    font-size: 6.5rem;
    margin-bottom: .2rem;
}

.h3-nenhum-resultado-encontrado {
    font-size: 3rem;
    font-weight: bold;
    margin-top: 0.5rem;
}

.referencia-para-tela-inicial {
    font-weight: bold;
    margin-top: .7rem;
}

.referencia-para-tela-inicial a {
    text-decoration: none;
    color: var(--azul-dixi);
}

.referencia-para-tela-inicial a:hover {
    text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/components/PaginaNaoEncontrada/PaginaNaoEncontrada.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,qBAAqB;IACrB,uBAAuB;AAC3B;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[".container-nenhuma-pagina-encontrada {\n    width: 100%;\n    height: 100vh;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.nenhum-encontrada-container {\n    margin-bottom: 4rem;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    color: var(--texto-cinza);\n}\n\n.nenhum-encontrada-container svg {\n    font-size: 6.5rem;\n    margin-bottom: .2rem;\n}\n\n.h3-nenhum-resultado-encontrado {\n    font-size: 3rem;\n    font-weight: bold;\n    margin-top: 0.5rem;\n}\n\n.referencia-para-tela-inicial {\n    font-weight: bold;\n    margin-top: .7rem;\n}\n\n.referencia-para-tela-inicial a {\n    text-decoration: none;\n    color: var(--azul-dixi);\n}\n\n.referencia-para-tela-inicial a:hover {\n    text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
