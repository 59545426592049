import React, { useEffect, useState } from 'react'
import './Notificacao.css'
import { IoIosNotifications } from 'react-icons/io'
import { getNotificacaoByUsuario, getTemNotificacao, marcaComoLida, marcarTodasComoLidas } from '../../services/notificacaoService'
import { useAuth } from '../../services/AuthContext'

function Notificacao() {
    const { user } = useAuth();

    const [notificacoes, setNotificacoes] = useState([]);
    const [temNotificacao, setTemNotificacao] = useState(false);
    const [mostraNotificacoes, setMostraNotificacoes] = useState(false);
    const [totalNotificacoes, setTotalNotificacoes] = useState(0);
    const [loading, setLoading] = useState(false);

    const [ tempoUltimaConsulta, setTempoUltimaConsulta ] = useState(0);

    useEffect(() => {
        const getTemNotificacaoFunc = async () => {
            const temNotificacaoNova = await getTemNotificacao(user.idUsuario);
            setTemNotificacao(temNotificacaoNova);
        }

        getTemNotificacaoFunc();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if(notificacoes.length <= 0) {
            setTemNotificacao(false);
        }
    }, [notificacoes])

    const handleGetNotificacoes = async () => {
        let consulta = true;

        if(Date.now() - tempoUltimaConsulta < 10000) {
            consulta = false;
        }

        if (!mostraNotificacoes && consulta) {
            setLoading(true);
            setTempoUltimaConsulta(Date.now());
            const notificacoes = await getNotificacaoByUsuario(user.idUsuario);
            setNotificacoes(notificacoes);
            setTotalNotificacoes(notificacoes.length);

            if(notificacoes.length > 0) {
                setTemNotificacao(true);
            }
            
            setLoading(false);
        }

        setMostraNotificacoes(!mostraNotificacoes);
    }

    const handleRemoveNotificacao = (idNotificacao) => {
        const notificacoesAtualizadas = notificacoes.filter((notificacao) => notificacao.idNotificacao !== idNotificacao);
        setTotalNotificacoes(notificacoesAtualizadas.length);
        setNotificacoes(notificacoesAtualizadas);
    }

    const handleClickNotificacao = async (idChamado, idNotificacao) => {
        if(totalNotificacoes === 1) {
            setTemNotificacao(false);
            setMostraNotificacoes(false);
        }

        handleRemoveNotificacao(idNotificacao);
        await marcaComoLida(idNotificacao);
        window.open(`/visualizar-chamado/${idChamado}`, "_blank");
    }

    const handleLerTodas = async () => {
        await marcarTodasComoLidas(user.idUsuario);
        setNotificacoes([]);
        setTemNotificacao(false);
        setMostraNotificacoes(false);
    }

    return (
        <div className={`container-notificacao`}>
            {temNotificacao && <span className="badge-notificacao"></span>}
            
            <IoIosNotifications 
                onClick={handleGetNotificacoes}
                id={`${mostraNotificacoes ? "mostra-tudo" : ""}`}
            />

            {mostraNotificacoes && (
                <div className="notificacoes-lista">
                    {loading ? (
                        <p>Carregando...</p>
                    ) : (
                        notificacoes.length > 0 ? (
                            <div className="container-bloco-notificacoes">
                                <div className="cabecalho-bloco-notificacoes">
                                    <h1 className="quantidade-notificacoes"><span className="quantidade-notificacoes-numero">{notificacoes.length}</span> notificações não lidas.</h1>
                                    <button className="botao-ler-todas-notificacoes" onClick={handleLerTodas}>Ler todas</button>
                                </div>
                                <hr className="divisor-cabecalho-notificacoes"/>
                                {notificacoes.map((notificacao) => (
                                    <div key={notificacao.idNotificacao} className="notificacao-item">
                                        <span className="notificacao-atuador">{notificacao.atuador.toUpperCase()}</span>
                                        <span className="notificacao-acao">
                                            {switchNotificacoes(notificacao.acaoNotificacao)}
                                        </span>
                                        <span
                                            className="notificacao-assunto"
                                            onClick={() => {
                                                handleClickNotificacao(notificacao.idChamado, notificacao.idNotificacao)
                                            }}
                                        >
                                            {notificacao.assuntoChamado}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p>Sem notificações não lidas no momento.</p>
                        )
                    )}
                </div>
            )}
        </div>
    )
}

const switchNotificacoes = (acaoNotificacao) => {
    const comentou = 1;
    const responsavel = 2;
    const status = 3;
    const abertura = 4;

    const espaco = " ";
    const doisPontos = ":";
    let textoRetorno = "";

    switch (acaoNotificacao.idAcaoNotificacao) {
        case comentou:
            textoRetorno = "comentou em um chamado que você está envolvido";
            break;
        case responsavel:
            textoRetorno = "te definiu como responsável em um chamado";
            break;
        case status:
            textoRetorno = "alterou o status de um chamado";
            break;
        case abertura:
            textoRetorno = "abriu um chamado e te definiu como responsável";
            break;
        default:
            textoRetorno = "ação desconhecida";
    }

    return espaco + textoRetorno + doisPontos + espaco;
}

export default Notificacao;
