import api from "./api";
import { getComentariosByChamado } from "./comentarioService";
import { getImagensByChamado } from "./imagemChamadoService";
import { montaUrlFiltroPaginacaoChamado } from "../utils/DataUtils";

const endpoint = "/chamado_";
const pageSize = 5;

export const getChamados = async (usuario, idStatus, page) => {
    try {
        const URL = endpoint + `/${usuario.idUsuario}/${idStatus}${montaUrlFiltroPaginacaoChamado(page, pageSize)}`; 
        const response = await api.get(URL);
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
}

export const getChamadosFiltrados = async (usuario, idStatus, page, filter, filterType) => {
    try {
        const URL = endpoint + `/${usuario.idUsuario}/${idStatus}${montaUrlFiltroPaginacaoChamado(page, pageSize, filter, filterType)}`; 
        const response = await api.get(URL);
        return response.data;
    } catch (error) {
        console.error(error);
        return [];
    }
}

export const getChamadoById = async (idChamado) => {
    try {

        let chamado = null;
        let tipoArquivo = null;
        let extensoesPermitidas = ["pdf", "docx", "txt", "csv"];
        // O chamado contém muitas informações, então quando editar o chamado, deve buscar também fotos/comentários vinculados a ele.

        // Buscando o chamado
        const chamadoEncontrado = await api.get(endpoint + `/${idChamado}`);
        chamado = chamadoEncontrado.data;

        // Instanciando a lista de imagens e comentários
        chamado.imagens = [];
        chamado.anexos = [];
        chamado.comentarios = [];

        // Buscando as imagens do chamado
        await getImagensByChamado(chamado).then((resposta) => {
            // Para cada chamado, adiciona na lista de imagens do chamado
            resposta.forEach((imagemChamado) => {
                tipoArquivo = imagemChamado.imagem.enderecoImagem.split('.').pop();

                if (extensoesPermitidas.includes(tipoArquivo)) {
                    chamado.anexos.push(imagemChamado.imagem);
                } else {
                    chamado.imagens.push(imagemChamado.imagem);
                }
            });
        });

        // Buscando os comentários do chamado
        await getComentariosByChamado(chamado).then((resposta) => {
            resposta.forEach((comentario) => {
                // Para cada chamado, instancia um comentário reduzido
                let comentarioReduzido = {
                    idComentario: comentario.idComentario,
                    descricao: comentario.descricao,
                    usuarioComentario: {
                        idUsuario: comentario.usuarioComentario.idUsuario,
                        nome: comentario.usuarioComentario.nome
                    },
                    imagens: comentario.imagens,
                    dataCriacao: comentario.dataCriacao
                };

                chamado.comentarios.push(comentarioReduzido);
            })
        });

        return chamado;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export const createChamado = async (chamado) => {
    try {
        const response = await api.post(endpoint, chamado, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
}

export const updateChamado = async (chamado, idUsuario) => {
    try {
        const response = await api.put(endpoint + `/${chamado.idChamado}/${idUsuario}`, chamado, {
            headers: {
                "Content-Type": "application/json",
            },
        });
        return response.data;
    } catch (error) {
        console.error(error);
        return null;
    }
};