import PaginaNaoEncontrada from "../components/PaginaNaoEncontrada/PaginaNaoEncontrada"


function NaoEncontrado() {
  return (
    <div className="main-container">
      <PaginaNaoEncontrada />
    </div>
  )
}

export default NaoEncontrado