// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-filtro-departamentos {
    background-color: var(--branco-padrao);
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
    padding: .5rem .8rem 1rem .8rem;
    display: flex;
    flex-direction: row;
    border-radius: 0 0 8px 8px;
    gap: 1rem;
}

.container-inputs-filtro-departamento {
    display: flex;
    flex-direction: row;
    gap: .5rem;
    width: 100%;
}

.botoes-filtro-departamentos {
    display: flex;
    flex-direction: row;
    align-items: end;
    gap: 1rem;
}

.input-nome-departamento {
    flex-grow: 3;
}

.input-situacao-cadastro-filtro-departamento {
    flex-grow: 1;
}`, "",{"version":3,"sources":["webpack://./src/components/FiltroDepartamentoContainer/FiltroDepartamentoContainer.css"],"names":[],"mappings":"AAAA;IACI,sCAAsC;IACtC,8CAA8C;IAC9C,+BAA+B;IAC/B,aAAa;IACb,mBAAmB;IACnB,0BAA0B;IAC1B,SAAS;AACb;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,UAAU;IACV,WAAW;AACf;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;IAChB,SAAS;AACb;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".container-filtro-departamentos {\n    background-color: var(--branco-padrao);\n    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);\n    padding: .5rem .8rem 1rem .8rem;\n    display: flex;\n    flex-direction: row;\n    border-radius: 0 0 8px 8px;\n    gap: 1rem;\n}\n\n.container-inputs-filtro-departamento {\n    display: flex;\n    flex-direction: row;\n    gap: .5rem;\n    width: 100%;\n}\n\n.botoes-filtro-departamentos {\n    display: flex;\n    flex-direction: row;\n    align-items: end;\n    gap: 1rem;\n}\n\n.input-nome-departamento {\n    flex-grow: 3;\n}\n\n.input-situacao-cadastro-filtro-departamento {\n    flex-grow: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
