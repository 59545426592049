import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const configPadrao = {
  position: "top-center",
  autoClose: 1500,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  progress: undefined,
};

const NotifyUtils = {
  sucesso: (message, options = {}) => {
    toast.success(message, { ...configPadrao, ...options });
  },
  erro: (message, options = {}) => {
    toast.error(message, { ...configPadrao, ...options });
  },
  aviso: (message, options = {}) => {
    toast.warn(message, { ...configPadrao, ...options });
  },
  info: (message, options = {}) => {
    toast.info(message, { ...configPadrao, ...options });
  },
  custom: (message, options = {}) => {
    toast(message, { ...configPadrao, ...options });
  },
  promise: (promise, loadingMessage, successMessage, errorMessage, options = {}) => {
    let tPromise = toast.promise(promise, {
      pending: loadingMessage,
      success: successMessage,
      error: errorMessage,
    }, {
      ...configPadrao,
      ...options
    });

    return tPromise;
  }
};

export default NotifyUtils;